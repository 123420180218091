import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledRole = styled('div')`
  .content-container {
    background-color: ${COLOR.white};
    border: 1px solid #ebe9f1;
    border-radius: 6px;
  }

  .list-container {
    min-height: 60vh;
    border-right: 1px solid #ebe9f1;
    padding: 20px 0;

    .box-btn-add {
      margin: 20px 14px;
      font-weight: normal;
    }

    .list-role {
      max-height: calc(100vh - 280px);
      overflow: auto;
    }
  }

  .btn-normal {
    font-weight: normal;
  }

  .btn-delete-role {
    color: ${COLOR.dark};
    border-color: ${COLOR.dark};
  }

  .list-permission {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
`;

export const StyledListPermission = styled('div')`
  padding: 20px 36px;
  border-bottom: 1px solid #ebe9f1;

  .form-control-parent {
    margin-bottom: 25px;
  }

  .header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .item {
    padding-top: 0 !important;

    .checkbox {
      .MuiTypography-root {
        color: ${COLOR.dark};
        line-height: 24px;
      }
    }
  }
`;
