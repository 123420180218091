import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { gapi } from 'gapi-script';

import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import NotiHandler from './errors/NotiHandler';
import store from './redux/store';
import AppRouter from './router';
import theme from './styles/theme';

const App = () => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Provider store={store}>
            <NotiHandler>
              <AppRouter />
            </NotiHandler>
          </Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
