import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { StyledUnauthorized } from './index.style';

const Unauthorized = () => {
  const { t } = useTranslation(['common', 'layout']);

  return (
    <StyledUnauthorized>
      <Box className="vbee-studio-logo">
        <img src="/img/logo-3.svg" alt="abi-logo" />
      </Box>

      <Typography className="unauthorized-title center">
        {t('unauthorizedTitle')}
      </Typography>
      <img
        src="/img/unauthorized.png"
        alt="unauthorized"
        className="unauthorized-img"
      />
    </StyledUnauthorized>
  );
};

export default Unauthorized;
