import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { COLOR } from '@src/styles/color';

export default styled(Box)`
  text-align: center;
  .success-image {
    height: auto;
    max-width: 100%;
  }

  .success-body {
    display: inline-block;
    margin-top: 35px;
  }
  .success-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2.25rem;
    background: transparent;
    color: #fff;
    border: 1px solid;
  }

  .success-icon {
    font-size: '4.5rem';
    color: ${COLOR.success};
  }

  .success-box {
    text-align: start;
    margin-left: 20px;
    padding: 1.5rem 0rem;
  }

  .MuiTypography-root {
    margin: 0px;
  }

  .success-title {
    color: ${COLOR.primary};
    font-size: 1.25rem;
  }

  .success-description {
    margin-top: 12px;
  }
  .back-to-login {
    margin-top: 20px;
  }
`;
