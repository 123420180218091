import snakecaseKeys from 'snakecase-keys';
import api from './api';

export const updateUser = async (userId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/api/admin/users/${userId}`,
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};
export const createUser = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/api/admin/users',
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const getListUsers = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/users',
    params,
  });
  return res;
};
