export const roles = [
  {
    id: 1,
    name: 'Admin',
    key: 'ADMIN',
    color: '#F5385B',
    bgColor: 'rgba(245, 56, 91, 0.12)',
    permissions: [
      {
        method: 'GET',
        path: '/client',
      },
      {
        method: 'GET',
        path: '/client/app',
      },
      {
        method: 'GET',
        path: '/oauth',
      },
    ],
  },
  {
    id: 2,
    name: 'CSKH',
    key: 'CSKH',
    color: '#7367F0',
    bgColor: 'rgba(115, 103, 240, 0.12)',
    permissions: [
      {
        method: 'POST',
        path: '/oauth/verify-signature',
      },
      {
        method: 'POST',
        path: '/oauth/combine-accounts',
      },
      {
        method: 'GET',
        path: '/secure',
      },
    ],
  },
  {
    id: 3,
    name: 'Sale',
    key: 'SALE',
    color: '#2DCE8B',
    bgColor: 'rgba(45, 206, 139, 0.12)',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/admins',
      },
      {
        method: 'POST',
        path: '/api/admin/admins',
      },
    ],
  },
  {
    id: 4,
    name: 'accountant',
    key: 'ACCOUNTANT',
    color: '#242424',
    bgColor: 'rgba(36, 36, 36, 0.12)',
    permissions: [
      {
        method: 'PUT',
        path: '/api/admin/user-roles',
      },
      {
        method: 'DELETE',
        path: '/api/admin/user-roles',
      },
    ],
  },
];

export const groupPermissions = [
  {
    id: 0,
    name: 'other',
    permissions: [
      {
        method: 'GET',
        path: '/client',
      },
      {
        method: 'GET',
        path: '/client/app',
      },
      {
        method: 'GET',
        path: '/oauth',
      },
      {
        method: 'POST',
        path: '/oauth/signup',
      },
      {
        method: 'POST',
        path: '/oauth/authorize',
      },
      {
        method: 'POST',
        path: '/oauth/authorize-mobiles',
      },
      {
        method: 'POST',
        path: '/oauth/token',
      },
      {
        method: 'POST',
        path: '/oauth/me',
      },
      {
        method: 'POST',
        path: '/oauth/nonces',
      },
      {
        method: 'POST',
        path: '/oauth/verify-signature',
      },
      {
        method: 'POST',
        path: '/oauth/combine-accounts',
      },
      {
        method: 'GET',
        path: '/secure',
      },
      {
        method: 'GET',
        path: '*',
      },
    ],
  },
  {
    id: 1,
    name: 'admin',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/admins',
      },
      {
        method: 'POST',
        path: '/api/admin/admins',
      },
      {
        method: 'PUT',
        path: '/api/admin/admins/:admin_id',
      },
      {
        method: 'DELETE',
        path: '/api/admin/admins/:admin_id',
      },
    ],
  },
  {
    id: 2,
    name: 'auth',
    permissions: [
      {
        method: 'POST',
        path: '/api/admin/auths/login',
      },
      {
        method: 'GET',
        path: '/api/admin/auths/verify',
      },
    ],
  },
  {
    id: 3,
    name: 'client',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/clients/:id',
      },
      {
        method: 'PUT',
        path: '/api/admin/clients/:id',
      },
      {
        method: 'DELETE',
        path: '/api/admin/clients/:id',
      },
      {
        method: 'POST',
        path: '/api/admin/clients',
      },
      {
        method: 'GET',
        path: '/api/admin/clients',
      },
    ],
  },
  {
    id: 4,
    name: 'permission',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/permissions',
      },
    ],
  },
  {
    id: 5,
    name: 'role',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/roles',
      },
      {
        method: 'POST',
        path: '/api/admin/roles',
      },
      {
        method: 'PUT',
        path: '/api/admin/roles/:role_id',
      },
      {
        method: 'DELETE',
        path: '/api/admin/roles/:role_id',
      },
    ],
  },
  {
    id: 6,
    name: 'user',
    permissions: [
      {
        method: 'GET',
        path: '/api/admin/users/:userId',
      },
      {
        method: 'GET',
        path: '/api/admin/users',
      },
      {
        method: 'POST',
        path: '/api/admin/users',
      },
      {
        method: 'PUT',
        path: '/api/admin/users/:user_id',
      },
    ],
  },
  {
    id: 7,
    name: 'userRole',
    permissions: [
      {
        method: 'POST',
        path: '/api/admin/user-roles',
      },
      {
        method: 'PUT',
        path: '/api/admin/user-roles',
      },
      {
        method: 'DELETE',
        path: '/api/admin/user-roles',
      },
      {
        method: 'GET',
        path: '/api/admin/user-roles',
      },
    ],
  },
];
