import { ERROR_CODE } from '@src/errors/code';

const getErrorMessage = (code, service) => {
  switch (code) {
    case ERROR_CODE.BAD_REQUEST:
      return 'badRequest';
    case ERROR_CODE.UNAUTHORIZED:
      return 'unauthorized';
    case ERROR_CODE.FORBIDDEN:
      return 'forbidden';
    case ERROR_CODE.NOT_FOUND:
      return 'notFound';
    case ERROR_CODE.USER_EXISTS:
      return 'userExists';
    case ERROR_CODE.USER_NOT_EXISTS:
      return 'userNotExists';
    case ERROR_CODE.ROLE_EXISTS:
      return 'roleExists';
    case ERROR_CODE.ROLE_NOT_EXISTS:
      return 'roleNotExists';
    case ERROR_CODE.ADMIN_EXISTS:
      return 'adminExists';
    case ERROR_CODE.ADMIN_NOT_EXISTS:
      return 'adminNotExists';
    case ERROR_CODE.CLIENT_EXISTS:
      return 'clientExists';
    case ERROR_CODE.CLIENT_NOT_EXISTS:
      return 'clientNotExists';
    case ERROR_CODE.CAPTCHA_INVALID:
      return 'captchaInvalid';
    case ERROR_CODE.SESSION_INVALID:
      return 'sessionInvalid';
    case ERROR_CODE.OTP_CODE_NOT_EXISTS:
      return 'otpCodeNotExists';
    case ERROR_CODE.OTP_CODE_EXPIRED:
      return 'otpCodeExpired';
    case ERROR_CODE.PASSWORD_INVALID:
      return 'passwordDoesNotMatch';
    case ERROR_CODE.USER_DEACTIVED:
      return 'userNotActive';
    default:
      return 'serverError';
  }
};

export default getErrorMessage;
