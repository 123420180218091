import * as auth from './auth';
import * as role from './role';
import * as userRole from './userRole';
import * as client from './client';
import * as user from './user';
import * as admin from './admin';
import * as permission from './permission';
import * as adminRole from './adminRole';
import * as oauth from './oauth';
import * as code from './code';

export default {
  auth,
  role,
  userRole,
  client,
  user,
  admin,
  permission,
  adminRole,
  oauth,
  code,
};
