import snakecaseKeys from 'snakecase-keys';

import api from './api';

const forgotPassword = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/oauth/forgot-password',
    data: snakecaseKeys(data, { deep: false }),
  });

  return res;
};

const loginSSO = async (data) => {
  const response = await api({
    method: 'POST',
    url: '/oauth/authorize',
    data,
    isRedirectRequest: true,
  });
  return response;
};

const registerSSO = async (data) => {
  const response = await api({
    method: 'POST',
    url: '/oauth/signup',
    data,
  });
  return response;
};

const resetPassword = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/oauth/reset-password',
    data: snakecaseKeys(data, { deep: false }),
  });

  return res;
};

const getNonces = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/oauth/nonces',
    data: snakecaseKeys(data, { deep: false }),
  });

  return res;
};

const verifySignatures = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/oauth/verify-signature',
    data: snakecaseKeys(data, { deep: false }),
    isRedirectRequest: true,
  });

  return res;
};

const loginSocial = async ({
  accessToken,
  provider,
  clientId,
  redirectUri,
}) => {
  const response = await api({
    method: 'POST',
    url: '/oauth/login-social',
    data: snakecaseKeys(
      {
        accessToken,
        provider,
        clientId,
        redirectUri,
      },
      { deep: false },
    ),
    isRedirectRequest: true,
  });

  return response;
};

export {
  forgotPassword,
  resetPassword,
  loginSSO,
  registerSSO,
  getNonces,
  verifySignatures,
  loginSocial,
};
