import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, Box, Button, Typography } from '@mui/material';
import { ROUTES } from '@src/constants';
import { actionTypes } from '@src/redux/auth/actions';
import { StyledLogin } from './index.style';

export default function Login() {
  const { t } = useTranslation(['user']);
  const history = useHistory();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loginInput, setLoginInput] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (accessToken) {
      history.push(ROUTES.CLIENTS);
    }
  }, [accessToken]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setLoginInput({
      ...loginInput,
      email,
    });
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    setLoginInput({
      ...loginInput,
      password,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch({
      type: actionTypes.LOGIN,
      payload: {
        email: loginInput.email,
        password: loginInput.password,
      },
    });
  };

  return (
    <StyledLogin>
      <Box>
        <form onSubmit={handleLogin}>
          <Typography className="title" type="email">
            ABI SSO
          </Typography>
          <TextField
            className="text-field"
            id="standard-input"
            label="Email"
            onChange={handleEmailChange}
          />
          <TextField
            className="text-field"
            id="standard-password-input"
            label={t('password')}
            type="password"
            autoComplete="current-password"
            onChange={handleChangePassword}
          />
          <Button type="submit" className="login-button" variant="contained">
            {t('login')}
          </Button>
        </form>
      </Box>
    </StyledLogin>
  );
}
