import React, { useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import apis from '@src/apis';
import AdminManagement from '@src/components/AdminManagement';
import AdminForm from '@src/components/AdminForm';
import VisibilityInput from '@src/components/VisibilityInput';

import { usePaginationWithState } from '@src/hooks';
import { FILTER_TYPE, GRANT_TYPE } from '@src/constants';
import ListClientStyle from './index.style';

const ListClient = () => {
  const START_MONTH = new Date(moment().startOf('month'));
  const END_MONTH = new Date(moment().endOf('month'));
  const { t } = useTranslation(['client']);
  const {
    data: listClients,
    handleCallApi: fetchListClients,
    currentPage,
    total,
    totalPage,
    limit,
    loading,
    onPaginationChange,
    onParamsChange,
    searchParams,
  } = usePaginationWithState([], apis.client.getListClients, true, {
    startTime: START_MONTH,
    endTime: END_MONTH,
  });

  const [formUpdateFields, setFormUpdateFields] = useState({
    grants: [],
    redirectUris: [],
    name: '',
  });
  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [formCreateFields, setFormCreateFields] = useState({
    grants: [],
    redirectUris: [],
    name: '',
  });
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [errorFormCreate, setErrorFormCreate] = useState({});
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [formDeleteFields, setFormDeleteFields] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const showFormUpdateItem = (item) => {
    setFormUpdateFields(
      camelcaseKeys(
        {
          ...formUpdateFields,
          ...item,
        },
        { deep: false },
      ),
    );
    setShowFormUpdate(true);
  };

  const showFormDeleteItem = (item) => {
    setShowFormDelete(true);
    setFormDeleteFields(item);
  };

  const showFormCreateItem = () => {
    setShowFormCreate(true);
    setErrorFormCreate({});
    setFormCreateFields({
      grants: [],
      redirectUris: [],
      name: '',
    });
  };

  const actions = [
    {
      key: 'edit',
      icon: <EditIcon />,
      onClick: (item) => showFormUpdateItem(item),
    },
    {
      key: 'delete',
      icon: <DeleteIcon className="delete-icon" />,
      onClick: (item) => showFormDeleteItem(item),
    },
  ];

  const validateFormCreate = () => {
    const { name, grants, redirectUris } = formCreateFields;

    let errorApp = {};

    if (!name) {
      errorApp.name = 'fieldRequired';
    }
    if (!grants || grants.length === 0) {
      errorApp.grants = 'fieldRequired';
    }
    if (!redirectUris || redirectUris.length === 0) {
      errorApp.redirectUris = 'fieldRequired';
    }

    errorApp = { ...errorFormCreate, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrorFormCreate(errorApp);
      return false;
    }

    return true;
  };

  const handleConfirmCreate = async () => {
    if (!validateFormCreate()) return;

    const { grants, redirectUris } = formCreateFields;
    try {
      const res = await apis.client.createClient({
        ...formCreateFields,
        grants: typeof grants === 'string' ? grants.split(',') : grants,
        redirectUris:
          typeof redirectUris === 'string'
            ? redirectUris.split(',')
            : redirectUris,
      });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('createClientSuccess'), { variant: 'success' });
      fetchListClients(searchParams);
      setShowFormCreate(false);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmUpdate = () => {
    setShowFormUpdate(false);
  };

  const handleConfirmUpdate = async ({ id, name, grants, redirectUris }) => {
    try {
      const res = await apis.client.updateClient(id, {
        name,
        grants: typeof grants === 'string' ? grants.split(',') : grants,
        redirectUris:
          typeof redirectUris === 'string'
            ? redirectUris.split(',')
            : redirectUris,
      });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('updateClientSuccess'), { variant: 'success' });
      fetchListClients(searchParams);
      handleCloseConfirmUpdate();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setShowFormDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (!formDeleteFields || !formDeleteFields.id)
        throw new Error('invalidValue');

      const res = await apis.client.deleteClient(formDeleteFields.id);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('deleteClientSuccess'), { variant: 'success' });
      fetchListClients(searchParams);
      handleCloseConfirmDelete();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const clientHeaders = [
    {
      label: t('no'),
      valueName: 'no',
      align: 'left',
    },
    {
      label: t('clientName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('clientId'),
      valueName: 'clientId',
      align: 'left',
    },
    {
      label: t('clientSecret'),
      valueName: 'customClientSecret',
      align: 'left',
    },
    {
      label: t('grants'),
      valueName: 'customGrants',
      align: 'left',
    },
    {
      label: t('redirectUris'),
      valueName: 'customRedirectUris',
      align: 'left',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  return (
    <ListClientStyle>
      <AdminManagement
        items={listClients.map((item) => ({
          ...item,
          customGrants: item.grants.join(','),
          customRedirectUris: item.redirectUris.join(','),
          customClientSecret: <VisibilityInput value={item.clientSecret} />,
        }))}
        headers={clientHeaders}
        actions={actions}
        onFetchData={fetchListClients}
        loading={loading}
        pagination={{
          page: currentPage,
          totalPages: totalPage,
          limit,
          total,
        }}
        onChangePagination={onPaginationChange}
        onParamsChange={onParamsChange}
        searchParams={searchParams}
        filters={[
          {
            field: 'dateRange',
            type: FILTER_TYPE.DATE_RANGE,
            default: [START_MONTH, END_MONTH],
          },
        ]}
        onCreateItem={showFormCreateItem}
      />
      {/* create client */}
      <AdminForm
        width="650px"
        open={showFormCreate}
        onClose={() => setShowFormCreate(false)}
        onOk={handleConfirmCreate}
        okMessage={t('create')}
        title={t('createClient')}
        fields={[
          {
            field: 'name',
            label: t('clientName'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
          {
            field: 'grants',
            label: t('grants'),
            type: FILTER_TYPE.MULTIPLE_SELECT,
            options: [
              {
                value: GRANT_TYPE.CLIENT_CREDENTIAL,
                label: GRANT_TYPE.CLIENT_CREDENTIAL,
              },
              {
                value: GRANT_TYPE.AUTHORIZATION_CODE,
                label: GRANT_TYPE.AUTHORIZATION_CODE,
              },
              {
                value: GRANT_TYPE.REFRESH_TOKEN,
                label: GRANT_TYPE.REFRESH_TOKEN,
              },
              {
                value: GRANT_TYPE.PASSWORD,
                label: GRANT_TYPE.PASSWORD,
              },
            ],
            multiple: true,
          },
          {
            field: 'redirectUris',
            label: t('redirectUris'),
            type: FILTER_TYPE.TEXT_FIELD,
          },
        ]}
        formData={formCreateFields}
        setFormData={setFormCreateFields}
        errors={errorFormCreate}
        setErrors={setErrorFormCreate}
      />
      {/* update client */}
      <AdminForm
        width="650px"
        open={showFormUpdate}
        onClose={handleCloseConfirmUpdate}
        onOk={handleConfirmUpdate}
        okMessage={t('update')}
        title={t('updateClient')}
        fields={[
          {
            field: 'name',
            label: t('clientName'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
          {
            field: 'clientId',
            label: t('clientId'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
            disabled: true,
          },
          {
            field: 'grants',
            label: t('grants'),
            type: FILTER_TYPE.MULTIPLE_SELECT,
            options: [
              {
                value: GRANT_TYPE.CLIENT_CREDENTIAL,
                label: GRANT_TYPE.CLIENT_CREDENTIAL,
              },
              {
                value: GRANT_TYPE.AUTHORIZATION_CODE,
                label: GRANT_TYPE.AUTHORIZATION_CODE,
              },
              {
                value: GRANT_TYPE.REFRESH_TOKEN,
                label: GRANT_TYPE.REFRESH_TOKEN,
              },
              {
                value: GRANT_TYPE.PASSWORD,
                label: GRANT_TYPE.PASSWORD,
              },
            ],
            multiple: true,
          },
          {
            field: 'redirectUris',
            label: t('redirectUris'),
            type: FILTER_TYPE.TEXT_FIELD,
          },
        ]}
        formData={formUpdateFields}
        setFormData={setFormUpdateFields}
      />

      {/* delete client */}
      <AdminForm
        open={showFormDelete}
        onClose={handleCloseConfirmDelete}
        onOk={handleConfirmDelete}
        okMessage={t('delete')}
        title={t('common:::confirmQuestion')}
        fields={[]}
        formData={formDeleteFields}
      />
    </ListClientStyle>
  );
};

export default ListClient;
