import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledNavbar = styled('div')`
  padding: 12px;
  width: 90%;
  margin: 0 auto;

  p {
    font-weight: 400;
  }
  .logo {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
  }
  .redirect {
    color: #fedc5a;
    cursor: pointer;
  }
`;
