import snakecaseKeys from 'snakecase-keys';

import api from './api';

export const getListUserRoles = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/user-roles',
    params: params ? snakecaseKeys(params, { deep: false }) : {},
  });
  return res;
};

export const updateUserRole = async (params, data) => {
  const res = await api({
    method: 'PUT',
    url: '/api/admin/user-roles',
    params: snakecaseKeys(params, { deep: false }),
    data: snakecaseKeys(data, { deep: false }),
  });
  return res;
};

export const deleteUserRole = async (params) => {
  const res = await api({
    method: 'DELETE',
    url: '/api/admin/user-roles',
    params: snakecaseKeys(params, { deep: false }),
  });
  return res;
};
