import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { COLOR } from '@src/styles/color';

export default styled(Box)`
  text-align: center;
  .error-image {
    height: auto;
    max-width: 100%;
  }

  .error-body {
    display: inline-block;
    margin-top: 35px;
  }
  .error-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2.25rem;
    background: transparent;
    color: #fff;
    border: 1px solid;
  }

  .error-icon {
    font-size: '4.5rem';
    color: ${COLOR.primary};
  }

  .error-box {
    text-align: start;
    margin-left: 20px;
    padding: 1.5rem 0rem;
  }

  .MuiTypography-root {
    margin: 0px;
  }

  .error-title {
    color: ${COLOR.primary};
    font-size: 1.25rem;
  }

  .error-description {
    margin-top: 12px;
  }
`;
