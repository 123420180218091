import WalletConnect from '@walletconnect/web3-provider';

import { INFURA_KEY } from '../../configs';

export const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: INFURA_KEY, // required
    },
  },
};
