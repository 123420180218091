import api from './api';

const login = async (email, password) => {
  const response = await api({
    method: 'POST',
    url: '/api/admin/auths/login',
    data: { email, password },
  });
  return response.result;
};

const verifyToken = async (accessToken) => {
  const response = await api({
    method: 'GET',
    url: '/api/admin/auths/verify',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response;
};

export { login, verifyToken };
