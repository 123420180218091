/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ABI_GALAVERSE_URL } from '@src/configs';
import ROUTES from '@src/constants/route';

import { StyledNavbar } from './index.style';

const Navbar = ({ children, collapsed }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(['user']);

  const renderDescription = () => {
    switch (location.pathname) {
      case ROUTES.LOGIN_SSO:
        return (
          <Box display="flex">
            <Typography fontSize="14px">
              {t('haveNotAccountQuestion')}?
            </Typography>
            <Box
              onClick={() =>
                history.push(`${ROUTES.REGISTER_SSO}${location.search}`)
              }
            >
              <Typography fontSize="14px" ml={1} className="redirect">
                {t('register')}!
              </Typography>
            </Box>
          </Box>
        );
      case ROUTES.REGISTER_SSO:
      case ROUTES.FORGOT_PASSWORD:
        return (
          <Box display="flex">
            <Typography fontSize="14px">{t('haveAccountQuestion')}?</Typography>
            <Box
              onClick={() =>
                history.push(`${ROUTES.LOGIN_SSO}${location.search}`)
              }
            >
              <Typography fontSize="14px" ml={1} className="redirect">
                {t('login')}!
              </Typography>
            </Box>
          </Box>
        );
      default:
    }
    return null;
  };

  return (
    <StyledNavbar>
      <div className="content">
        <div className="right-content">
          <img
            className={`logo ${collapsed && 'hide'} }`}
            src="/img/logo-galaverse.svg"
            alt="logo"
            onClick={() => {
              window.location.href = ABI_GALAVERSE_URL;
            }}
          />
        </div>
        {/* <div className="left-content">{renderDescription()}</div> */}
      </div>
    </StyledNavbar>
  );
};

export default Navbar;
