import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import StyledErrorInfo from './index.style';

const ErrorInfo = ({ message }) => (
  <StyledErrorInfo>
    <Box>
      <img className="error-image" src="/img/bg-login.png" alt="" />
    </Box>
    <Box className="error-body">
      <Paper elevation={3} className="error-info">
        <div>
          <ErrorIcon
            className="error-icon"
            sx={{ fontSize: '4.5rem', color: 'red' }}
          />
        </div>
        <Box className="error-box">
          <Typography mt={3} className="error-title">
            Chúng tôi xin lỗi vì sự cố này.
          </Typography>
          <Typography mt={3} className="error-description">
            {message}
          </Typography>
        </Box>
      </Paper>
    </Box>
  </StyledErrorInfo>
);

export default ErrorInfo;
