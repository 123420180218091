export const SERVICE = {
  PORTAL: 'PORTAL',
  PAYMENT: 'PAYMENT',
  CALL: 'CALL',
  PUBLIC_API: 'PUBLIC_API',
};

export const ERROR_CODE_SYSTEM = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

export const ERROR_CODE_PORTAL_SERVICE = {
  // ERROR CODE HOTLINE: 1500-1599
  HOTLINE_EXISTS: 1500,
  HOTLINE_NOT_FOUND: 1501,
  HOTLINE_LINKED_CAMPAIGN: 1502,
  // ERROR CODE PROVIDER SIPTRUNK: 2000-2099
  PROVIDER_SIPTRUNK_NAME_EXIST: 2000,
  PROVIDER_SIPTRUNK_NOT_FOUND: 2001,
  PROVIDER_SIPTRUNK_LINKED_HOTLINE: 2002,
};

export const ERROR_CODE_PAYMENT_SERVICE = {};

export const ERROR_CODE_CALL_SERVICE = {};

export const ERROR_CODE_PUBLIC_SERVICE = {};
