import { styled } from '@mui/material/styles';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledLayout = styled('div')`
  display: flex;
  background-image: url('/img/background-oauth.png');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  .main {
    flex: 1;
    overflow: hidden;
    transition: all 0.25s;
    transition-duration: 0.4s;
  }

  .navbar {
    margin-bottom: 20px;
    background: #fff;
    border-radius: ${BORDER_RADIUS};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    background-color: transparent;
  }
`;
