import React, { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Box,
  Button,
  Typography,
  Link,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';

import apis from '@src/apis';
import { ROUTES, REGEX_EMAIL } from '@src/constants';
import SuccessInfo from '@src/components/SuccessInfo';
import CustomSliderCaptcha from '@src/components/CustomSliderCaptcha';
import StyledForgotPassword from './index.style';

const stylePlaceHolder = { color: '#fff', fontWeight: '600', fontSize: '14px' };

export default function ForgotPassword() {
  const { t } = useTranslation(['user', 'common']);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    email: '',
    captchaToken: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    captchaToken: '',
  });
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const recaptchaRef = useRef(null);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData({
      ...formData,
      email,
    });
    setErrors({ email: '' });
  };

  const handleChangeRecaptcha = (captchaToken) => {
    setFormData({
      ...formData,
      captchaToken,
    });
    setErrors({ captchaToken: '' });
  };

  const handleBackToLogin = () => {
    history.push(`${ROUTES.LOGIN_SSO}${location.search}`);
  };

  const validateFormData = () => {
    const { email } = formData;

    let errorApp = {};
    if (!email) {
      errorApp.email = 'fieldRequired';
    }

    if (email && !String(email).toLowerCase().trim().match(REGEX_EMAIL)) {
      errorApp.email = 'emailInvalid';
    }

    errorApp = { ...errors, ...errorApp };
    const checkExistError = Object.values(errorApp).some((err) => err);
    if (checkExistError) {
      setErrors(errorApp);
      return false;
    }

    return true;
  };

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();
    if (!validateFormData()) return;

    if (!formData.sessionId || !formData.captchaToken) {
      setErrors({ captchaToken: 'fieldRequired' });
      return;
    }
    const params = queryString.parse(location.search);

    try {
      setLoadingForgotPassword(true);
      const res = await apis.oauth.forgotPassword({
        ...formData,
        ...params,
      });
      setLoadingForgotPassword(false);
      if (res.status) {
        enqueueSnackbar(t('forgotPasswordSuccess'), {
          variant: 'success',
        });
        setIsRequestSuccess(true);
        return;
      }
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
    setFormData({ ...formData, email: '' });
    setErrors({ email: '', captchaToken: '' });
    setLoadingForgotPassword(false);
  };

  const handleValidCaptcha = (token) => {
    handleChangeRecaptcha(token);
  };

  const handleChangeSession = (sessionId) => {
    setFormData({
      ...formData,
      sessionId,
    });
    setErrors({ captchaToken: '' });
  };

  if (isRequestSuccess) return <SuccessInfo message={t('pleaseCheckMail')} />;

  return (
    <StyledForgotPassword>
      <form onSubmit={onSubmitWithReCAPTCHA}>
        <Box>
          <Typography className="title" type="email" color="primary">
            {t('forgotPassword')}
          </Typography>
          <TextField
            className="text-field"
            id="standard-input"
            // label={t('enterEmail')}
            placeholder={t('enterEmail')}
            onChange={handleEmailChange}
            InputLabelProps={{
              style: stylePlaceHolder,
            }}
            error={Boolean(errors.email)}
            helperText={errors.email && t(`common:::${errors.email}`)}
            value={formData.email}
          />
          <Box marginY={3}>
            <CustomSliderCaptcha
              onSuccess={handleValidCaptcha}
              onChangeSession={handleChangeSession}
            />
            {Boolean(errors.captchaToken) && (
              <FormHelperText className="error_message">
                {errors.captchaToken && t(`common:::${errors.captchaToken}`)}
              </FormHelperText>
            )}
          </Box>

          {loadingForgotPassword ? (
            <LoadingButton
              loading
              loadingPosition="start"
              variant="outlined"
              className="submit-button"
            >
              {t('submit')}
            </LoadingButton>
          ) : (
            <Button type="submit" className="submit-button" variant="contained">
              {t('submit')}
            </Button>
          )}
          <Box display="flex" justifyContent="center" className="redirect">
            <Typography fontSize="14px">{t('haveAccountQuestion')}?</Typography>
            <Link
              href={`${ROUTES.LOGIN_SSO}${location.search}`}
              className="redirect-link"
            >
              <Typography fontSize="14px" ml={1}>
                {t('login')}!
              </Typography>
            </Link>
          </Box>

          <Button className="back-to-login" onClick={handleBackToLogin}>
            {t('backToLogin')}
          </Button>
        </Box>
      </form>
    </StyledForgotPassword>
  );
}
