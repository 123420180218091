import axios from 'axios';
import camelCase from 'camelcase-keys';
import { API_URL } from '@src/configs';
import getErrorMessage from '@src/errors/getErrorMessage';
import { getCookie } from '@src/utils/cookie';

const axiosClient = axios.create({
  baseURL: `${API_URL}`,
  responseType: 'json',
  timeout: 30 * 1000,
});

axiosClient.interceptors.request.use(async (config) => {
  const accessToken = getCookie('accessToken');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (!response) return {};

    if (response.config.isRedirectRequest) {
      return response;
    }

    const { config = {}, data } = response;
    const { source } = config;

    if (data && data instanceof Blob) {
      return data;
    }

    let newData = {};
    if (data) {
      newData = camelCase(data, { deep: true });
    }

    if (!newData.status) {
      const errorMessage = getErrorMessage(newData.code, source);
      throw new Error(errorMessage);
    }

    return newData;
  },
  (error) => {
    if (error.response && error.response.status) {
      const { config = {}, response } = error;
      const { source } = config;
      const code = response.data.code || response.status;
      const errorMessage = getErrorMessage(code, source);
      throw new Error(errorMessage);
    }
  },
);

export default axiosClient;
