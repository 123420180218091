/* eslint-disable no-bitwise */

import React, { useState, useEffect } from 'react';
import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BlockIcon from '@mui/icons-material/Block';

import apis from '@src/apis';
import AdminManagement from '@src/components/AdminManagement';
import AdminForm from '@src/components/AdminForm';
import CustomChip from '@src/components/CustomChip';

import { usePaginationWithState } from '@src/hooks';
import { FILTER_TYPE, STATUS_USER, REGEX_EMAIL } from '@src/constants';
import ROUTES from '@src/constants/route';
import ListUserStyle from './index.style';

const ListUser = () => {
  const START_MONTH = new Date(moment().startOf('month'));
  const END_MONTH = new Date(moment().endOf('month'));

  const { t } = useTranslation(['user']);
  const history = useHistory();
  const {
    data: listUsers,
    handleCallApi: fetchListUsers,
    currentPage,
    total,
    totalPage,
    limit,
    loading,
    onPaginationChange,
    onParamsChange,
    searchParams,
  } = usePaginationWithState([], apis.user.getListUsers, true, {
    startTime: START_MONTH,
    endTime: END_MONTH,
  });

  const [formUpdateFields, setFormUpdateFields] = useState({
    walletAddress: '',
    status: '',
  });
  const [formCreateFields, setFormCreateFields] = useState({
    username: '',
    password: '',
    walletAddress: '',
    status: STATUS_USER.ACTIVE,
  });

  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [errorFormCreate, setErrorFormCreate] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const showFormCreateItem = () => {
    setShowFormCreate(true);
    setErrorFormCreate({});
    setFormCreateFields({
      username: '',
      password: '',
      walletAddress: '',
      status: STATUS_USER.ACTIVE,
    });
  };

  const showFormUpdateItem = (item) => {
    setFormUpdateFields(
      camelcaseKeys(
        {
          ...formUpdateFields,
          ...item,
          status: item.status || `${item.status}`,
        },
        { deep: false },
      ),
    );
    setShowFormUpdate(true);
  };

  const showFormDeleteItem = () => {
    // eslint-disable-next-line no-console
    console.log('delete item');
  };

  const gotoPageUserRole = (item) => {
    history.push(`${ROUTES.USERS}/${item.id}`);
  };

  const actions = [
    {
      key: 'edit',
      icon: <BlockIcon />,
      onClick: (item) => showFormUpdateItem(item),
    },
    {
      key: 'delete',
      icon: <DeleteIcon className="delete-icon" />,
      onClick: (item) => showFormDeleteItem(item),
    },
    {
      key: 'info',
      icon: <ManageAccountsIcon className="manage-account" />,
      onClick: (item) => gotoPageUserRole(item),
    },
  ];

  const userHeaders = [
    {
      label: t('no'),
      valueName: 'no',
      align: 'left',
    },
    {
      label: t('username'),
      valueName: 'username',
      align: 'left',
    },
    {
      label: t('walletAddress'),
      valueName: 'walletAddress',
      align: 'left',
    },
    {
      label: t('status'),
      valueName: 'customStatus',
      align: 'left',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  const handleCloseConfirmUpdate = () => {
    setShowFormUpdate(false);
  };

  const handleConfirmUpdate = async ({ id, status, clientId, roleId }) => {
    try {
      const res = await apis.user.updateUser(id, { status });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('updateUserSuccess'), { variant: 'success' });
      fetchListUsers(searchParams);
      handleCloseConfirmUpdate();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const validateFormCreate = () => {
    const { username, password } = formCreateFields;

    let errorApp = {};

    if (!username) {
      errorApp.username = 'fieldRequired';
    }
    if (username && !String(username).trim().toLowerCase().match(REGEX_EMAIL)) {
      errorApp.username = 'emailInvalid';
    }

    if (!password) {
      errorApp.password = 'fieldRequired';
    }

    errorApp = { ...errorFormCreate, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrorFormCreate(errorApp);
      return false;
    }

    return true;
  };

  const handleConfirmCreate = async () => {
    if (!validateFormCreate()) return;

    try {
      const res = await apis.user.createUser(formCreateFields);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('createUserSuccess'), { variant: 'success' });
      fetchListUsers(searchParams);
      setShowFormCreate(false);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  return (
    <ListUserStyle>
      <AdminManagement
        items={listUsers.map((item) => ({
          ...item,
          customStatus: ~~item.status ? (
            <CustomChip label={t('active')} color="success" />
          ) : (
            <CustomChip label={t('deactive')} color="error" />
          ),
        }))}
        headers={userHeaders}
        actions={actions}
        onFetchData={fetchListUsers}
        onParamsChange={onParamsChange}
        searchParams={searchParams}
        loading={loading}
        pagination={{
          page: currentPage,
          totalPages: totalPage,
          limit,
          total,
        }}
        onChangePagination={onPaginationChange}
        filters={[
          {
            field: 'dateRange',
            type: FILTER_TYPE.DATE_RANGE,
            default: [START_MONTH, END_MONTH],
          },
          {
            field: 'status',
            type: FILTER_TYPE.SELECT,
            default: null,
            placeholder: t('selectStatus'),
            options: [
              {
                value: STATUS_USER.ACTIVE,
                label: 'Active',
              },
              {
                value: STATUS_USER.DEACTIVE,
                label: 'Deactive',
              },
            ],
          },
        ]}
        onCreateItem={showFormCreateItem}
      />

      {/* update user */}
      <AdminForm
        open={showFormUpdate}
        onClose={handleCloseConfirmUpdate}
        onOk={handleConfirmUpdate}
        okMessage={t('update')}
        title={t('blockUser')}
        fields={[
          {
            field: 'username',
            label: t('username'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
            disabled: true,
          },
          // {
          //   field: 'walletAddress',
          //   label: t('walletAddress'),
          //   type: FILTER_TYPE.TEXT_FIELD,
          //   defaultValue: '',
          //   disabled: true,
          // },
          {
            field: 'status',
            label: t('status'),
            type: FILTER_TYPE.SELECT,
            options: [
              {
                value: STATUS_USER.ACTIVE,
                label: t('active'),
              },
              {
                value: STATUS_USER.DEACTIVE,
                label: t('deactive'),
              },
            ],
          },
        ]}
        formData={formUpdateFields}
        setFormData={setFormUpdateFields}
      />

      {/* create user */}
      <AdminForm
        open={showFormCreate}
        onClose={() => setShowFormCreate(false)}
        onOk={handleConfirmCreate}
        okMessage={t('create')}
        title={t('createUser')}
        fields={[
          {
            field: 'username',
            label: t('username'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
          {
            field: 'password',
            label: t('password'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
            typeInput: 'password',
          },
          // {
          //   field: 'walletAddress',
          //   label: t('walletAddress'),
          //   type: FILTER_TYPE.TEXT_FIELD,
          //   defaultValue: '',
          // },
          {
            field: 'status',
            label: t('status'),
            type: FILTER_TYPE.SELECT,
            options: [
              {
                value: STATUS_USER.ACTIVE,
                label: 'Active',
              },
              {
                value: STATUS_USER.DEACTIVE,
                label: 'Deactive',
              },
            ],
          },
        ]}
        formData={formCreateFields}
        setFormData={setFormCreateFields}
        errors={errorFormCreate}
        setErrors={setErrorFormCreate}
      />
    </ListUserStyle>
  );
};

export default ListUser;
