import { actionTypes } from './actions';

export const initialState = {
  accessToken: null,
  isLoggingIn: false,
  verified: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.VERIFY_USER:
      return { ...state, verified: false };

    case actionTypes.VERIFY_USER_SUCCESS:
      return {
        ...state,
        verified: true,
        accessToken: action.accessToken,
      };

    case actionTypes.LOGIN:
      return { ...state, isLoggingIn: true, verified: false };

    case actionTypes.LOGIN_SUCCESS: {
      const { accessToken } = action;
      return { ...state, isLoggingIn: false, accessToken, verified: true };
    }

    case actionTypes.LOGIN_FAILURE: {
      return { ...state, isLoggingIn: false, verified: false };
    }

    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggingIn: false,
        accessToken: null,
        verified: false,
      };

    default:
      return state;
  }
}
