import { ERROR_CODE } from './code';

export const getMessage = (code) => {
  switch (code) {
    case ERROR_CODE.BAD_REQUEST:
      return 'badRequest';
    case ERROR_CODE.UNAUTHORIZED:
      return 'unauthorized';
    case ERROR_CODE.INTERNAL_SERVER_ERROR:
      return 'internalServerError';
    case ERROR_CODE.SHEET_ID_NOT_FOUND:
      return 'sheetIdNotFound';
    case ERROR_CODE.SHEET_CONFIG_REGISTERED:
      return 'spreadsheetRegistered';
    case ERROR_CODE.SPREAD_SHEET_NOT_WRITABLE:
      return 'spreadsheetNotWritable';
    default:
      return '';
  }
};
