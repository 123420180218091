import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import 'nprogress/nprogress.css';

import ROUTE from '@src/constants/route';
import Layout from '@src/containers/Layout';
import LayoutOAuth from '@src/containers/LayoutOAuth';

import { adminRoutes, otherRoutes } from './appRoutes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const PrivateApp = () => {
  const privateRoutes = adminRoutes.filter((route) => route.isPrivate);

  return (
    <Layout>
      <Switch>
        {privateRoutes.map((privateRoute) => (
          <PrivateRoute
            path={privateRoute.path}
            component={privateRoute.component}
            exact
            key={privateRoute.path}
          />
        ))}
        <Redirect to={ROUTE.CLIENTS} />
      </Switch>
    </Layout>
  );
};

const getOauthRoutes = () => (
  <LayoutOAuth>
    <Switch>
      {otherRoutes.map((publicRoute) => (
        <PublicRoute
          path={publicRoute.path}
          component={publicRoute.component}
          exact
          key={publicRoute.path}
          restricted={publicRoute.restricted}
        />
      ))}
      <Redirect to={ROUTE.NOT_FOUND} />
    </Switch>
  </LayoutOAuth>
);

const AppRouter = () => {
  const publicRoutes = adminRoutes.filter((route) => !route.isPrivate);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/admin">
            <Switch>
              {publicRoutes.map((publicRoute) => (
                <PublicRoute
                  key={publicRoute.path}
                  exact
                  path={publicRoute.path}
                  component={publicRoute.component}
                  restricted={publicRoute.restricted}
                />
              ))}

              <PrivateRoute component={PrivateApp} />
            </Switch>
          </Route>

          <Route>
            <PublicRoute component={getOauthRoutes} />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
