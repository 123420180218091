import React from 'react';
import { useTranslation } from 'react-i18next';

import SliderCaptcha from '@slider-captcha/react';
import { API_URL } from '@src/configs';

import StyledContainer from './index.style';

const CustomSliderCaptcha = ({ onSuccess, onChangeSession }) => {
  const { t } = useTranslation(['common']);

  const verifiedCallback = (token) => {
    if (typeof onSuccess === 'function') onSuccess(token);
  };

  const fetchCaptcha = () =>
    fetch(`${API_URL}/captcha/create`, {
      // Use create as API URL for fetch
      method: 'GET',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((message) => message.json())
      .then((res) => {
        onChangeSession(res.sessionId);
        return res;
      });

  const fetchVerifyCaptcha = (response, trail) =>
    fetch(`${API_URL}/captcha/verify`, {
      // Verification API URL provided instead
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'include',
      body: JSON.stringify({
        response,
        trail,
      }),
    }).then((message) => message.json());

  return (
    <StyledContainer>
      <SliderCaptcha
        create={fetchCaptcha}
        verify={fetchVerifyCaptcha}
        callback={verifiedCallback}
        text={{
          anchor: t('notRobot'),
          challenge: t('slideToComplete'),
        }}
      />
    </StyledContainer>
  );
};
export default CustomSliderCaptcha;
