import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@mui/material';
import { StyledCustomListRole } from './index.style';

const CustomListRole = ({ items, itemSelected, onSelectItem }) => {
  const { t } = useTranslation(['system', 'common']);

  return (
    <StyledCustomListRole>
      {items.map((item) => (
        <Box
          key={item.id}
          className={`item ${itemSelected.id === item.id && 'item-selected'}`}
          onClick={() => onSelectItem(item)}
        >
          <Typography className="title">
            {item.name || t('common:::other')}
          </Typography>
          <Typography className="description">
            {(item.permissions && item.permissions.length) || 0}{' '}
            {t('permissionUnit')}
          </Typography>
        </Box>
      ))}
    </StyledCustomListRole>
  );
};

export default CustomListRole;
