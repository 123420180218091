import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';
import { getCookie } from '@src/utils/cookie';
import { ROUTES } from '@src/constants';
import ROUTE from '@src/constants/route';
import actions from '../redux/actions';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const { accessToken, verified } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const verifyTokenAdmin = () => {
    if (accessToken) {
      history.push(ROUTES.CLIENTS);
      return;
    }

    const tokenFromCookie = getCookie('accessToken');
    if (tokenFromCookie) {
      dispatch(actions.auth.verifyUser(tokenFromCookie));
    }
  };

  useEffect(() => {
    if (restricted) {
      verifyTokenAdmin();
    }
  }, [accessToken, verified, dispatch]);

  if (verified) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken && restricted ? (
          <Redirect to={ROUTE.CLIENTS} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
