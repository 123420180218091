import styled from 'styled-components';

export default styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: center;
  text-align: center;

  .opp {
    font-family: 'Poppins';
    font-size: 60px;
    line-height: 90px;
    color: #99efd0;
    font-weight: 400;
  }

  .not-found-text {
    font-family: 'Poppins';
    font-size: 36px;
    line-height: 54px;
    color: #fff;
    font-weight: 300;
  }

  .go-back-button {
    background: linear-gradient(180deg, #e58916 0%, #fcac20 100%);
    text-transform: none;
    margin-top: 30px;
  }
`;
