import snakecaseKeys from 'snakecase-keys';

import api from './api';

const checkOtpCode = async (data) => {
  const res = await api({
    method: 'GET',
    url: '/codes/check',
    params: snakecaseKeys(data, { deep: false }),
  });

  return res;
};

export { checkOtpCode };
