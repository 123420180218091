const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,

  // ERROR CODE USER: 1000 - 1100
  USER_EXISTS: 1000,
  USER_NOT_EXISTS: 1001,
  PASSWORD_INVALID: 1002,
  USER_DEACTIVED: 1003,
  // ERROR CODE ROLE: 1101 - 1200
  ROLE_EXISTS: 1101,
  ROLE_NOT_EXISTS: 1102,
  // ERROR CODE ADMIN: 1201-1300
  ADMIN_EXISTS: 1201,
  ADMIN_NOT_EXISTS: 1202,
  // ERROR CODE CLIENT: 1301 - 1400
  CLIENT_EXISTS: 1301,
  CLIENT_NOT_EXISTS: 1302,
  // ERROR CAPTCHA: 1401-1500
  CAPTCHA_INVALID: 1401,
  SESSION_INVALID: 1402,

  // ERROR CODE OTP CODE: 1501-1599
  OTP_CODE_NOT_EXISTS: 1501,
  OTP_CODE_EXPIRED: 1502,
};

export { ERROR_CODE };
