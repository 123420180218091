module.exports = {
  LOGIN_SSO: '/login-sso',
  REGISTER_SSO: '/register-sso',
  HOME: '/',
  LOGIN: '/admin/login',
  ROLES: '/admin/roles',
  PERMISSIONS: '/admin/permissions',
  USERS: '/admin/users',
  CLIENTS: '/admin/clients',
  ADMINS: '/admin/admins',
  ADMIN_ROLE: '/admin/admin-role',
  ADMIN_PERMISSION: '/admin/admin-permission',
  UNAUTHORIZED: '/admin/403',
  NOT_FOUND: '/404',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
};
