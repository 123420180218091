import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import apis from '@src/apis';
import { REGEX_EMAIL, ROUTES } from '@src/constants';
import ErrorInfo from '@src/components/ErrorInfo';
import SuccessInfo from '@src/components/SuccessInfo';
import CustomSliderCaptcha from '@src/components/CustomSliderCaptcha';

import { StyledResetPassword } from './index.style';

export default function ResetPassword() {
  const { t } = useTranslation(['user', 'common']);
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const recaptchaRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({ captchaToken: '' });
  const [validParam, setValidParam] = useState(true);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const checkOtpCode = async ({ username, code }) => {
    let errorMessage = '';
    try {
      const res = await apis.code.checkOtpCode({ username, code });
      if (!res.status || !res.data) errorMessage = res.message;
    } catch (error) {
      errorMessage = t(`common:::${error.message}`);
    }

    if (errorMessage) {
      setValidParam(false);
      setErrors({ errorMessage });
    }
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { code, username } = searchParams;
    let errorMessage = '';
    if (!code) errorMessage = 'Invalid Request';

    if (!username) errorMessage = 'Username Not Found';

    if (username && !String(username).toLowerCase().match(REGEX_EMAIL))
      errorMessage = 'Invalid Username';

    if (errorMessage) {
      setValidParam(false);
      setErrors({ errorMessage });
      return;
    }

    setValidParam(true);
    setErrors({ errorMessage: '' });

    checkOtpCode({ username, code });
  }, [location.search]);

  const handleChangeRecaptcha = (captchaToken) => {
    setFormData({
      ...formData,
      captchaToken,
    });
    setErrors({ captchaToken: '' });
  };

  const validateForm = () => {
    const { password, confirmPassword } = formData;

    let errorApp = {};

    if (!password) {
      errorApp.password = 'fieldRequired';
    }

    if (!confirmPassword) {
      errorApp.confirmPassword = 'fieldRequired';
    }

    if (password && confirmPassword && password !== confirmPassword) {
      errorApp.confirmPassword = 'passwordDoesNotMatch';
    }

    if (password.length < 6) {
      errorApp.password = 'passwordInvalid';
    }

    errorApp = { ...errors, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrors(errorApp);
      return false;
    }

    return true;
  };

  const handleClickShowPassword = (type) => {
    setShowPassword({
      ...showPassword,
      [type]: !showPassword[type],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    setFormData({
      ...formData,
      password,
    });
    setErrors({ password: '' });
  };

  const handleChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setFormData({
      ...formData,
      confirmPassword,
    });
    setErrors({ confirmPassword: '' });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!formData.sessionId || !formData.captchaToken) {
      setErrors({ captchaToken: 'fieldRequired' });
      return;
    }

    const searchParams = queryString.parse(location.search);
    const { code, username } = searchParams;

    try {
      setLoadingResetPassword(true);
      const res = await apis.oauth.resetPassword({
        ...formData,
        code,
        username,
      });
      setLoadingResetPassword(false);
      if (res.status) {
        enqueueSnackbar(t('resetPasswordSuccess'), {
          variant: 'success',
        });
        setIsRequestSuccess(true);
        return;
      }
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
    setFormData({ password: '', confirmPassword: '' });
    setErrors({ password: '', confirmPassword: '', captchaToken: '' });
    setLoadingResetPassword(false);
  };

  const handleBackToLogin = () => {
    history.push(`${ROUTES.LOGIN_SSO}${location.search}`);
  };

  const handleValidCaptcha = (token) => {
    handleChangeRecaptcha(token);
  };

  const handleChangeSession = (sessionId) => {
    setFormData({
      ...formData,
      sessionId,
    });
    setErrors({ captchaToken: '' });
  };

  if (!validParam) return <ErrorInfo message={errors.errorMessage} />;
  if (isRequestSuccess)
    return (
      <SuccessInfo message={t('resetPasswordSuccess')}>
        <Button
          variant="contained"
          className="back-to-login"
          onClick={handleBackToLogin}
        >
          {t('backToLogin')}
        </Button>
      </SuccessInfo>
    );

  return (
    <StyledResetPassword>
      <Box>
        <form onSubmit={handleResetPassword}>
          <Typography className="title" type="email" color="primary">
            {t('resetPassword')}
          </Typography>
          <FormControl
            variant="outlined"
            className="text-field"
            error={Boolean(errors.password)}
          >
            {/* <InputLabel htmlFor="outlined-adornment-password">
              {t('newPassword')}
            </InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword.password ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.password ? (
                      <VisibilityOff color="primary" />
                    ) : (
                      <Visibility color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={t('newPassword')}
              // label="Password"
            />
            <FormHelperText id="component-error-text">
              {errors.password && t(`common:::${errors.password}`)}
            </FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            className="text-field"
            error={Boolean(errors.confirmPassword)}
          >
            {/* <InputLabel htmlFor="outlined-adornment-password">
              {t('confirmNewPassword')}
            </InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={handleChangeConfirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('confirmPassword')}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityOff color="primary" />
                    ) : (
                      <Visibility color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={t('confirmNewPassword')}
              // label={t('confirmPassword')}
            />
            <FormHelperText id="component-error-text">
              {errors.confirmPassword &&
                t(`common:::${errors.confirmPassword}`)}
            </FormHelperText>
          </FormControl>
          <Box marginY={3}>
            <CustomSliderCaptcha
              onSuccess={handleValidCaptcha}
              onChangeSession={handleChangeSession}
            />
            {Boolean(errors.captchaToken) && (
              <FormHelperText className="error_message">
                {errors.captchaToken && t(`common:::${errors.captchaToken}`)}
              </FormHelperText>
            )}
          </Box>

          {loadingResetPassword ? (
            <LoadingButton
              loading
              loadingPosition="start"
              variant="outlined"
              className="register-button"
            >
              {t('submit')}
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              className="register-button"
              variant="contained"
            >
              {t('submit')}
            </Button>
          )}

          <Box display="flex" justifyContent="center" className="redirect">
            <Typography fontSize="14px">{t('haveAccountQuestion')}?</Typography>
            <Link
              href={`${ROUTES.LOGIN_SSO}${location.search}`}
              className="redirect-link"
            >
              <Typography fontSize="14px" ml={1}>
                {t('login')}!
              </Typography>
            </Link>
          </Box>
        </form>
      </Box>
    </StyledResetPassword>
  );
}
