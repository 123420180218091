import ROUTES from './route';
import DATE_TIME_PICKER_TYPES from './dateTimePickerType';
import {
  SERVICE,
  ERROR_CODE_SYSTEM,
  ERROR_CODE_PORTAL_SERVICE,
  ERROR_CODE_PAYMENT_SERVICE,
  ERROR_CODE_CALL_SERVICE,
  ERROR_CODE_PUBLIC_SERVICE,
} from './responseCode';
import {
  HOTLINE_TYPE,
  HOTLINE_STATUS,
  MOBILE_NETWORK_OPERATOR,
} from './hotline';
import { PERMISSION_TYPE } from './permission';

export const A_WEEK = 7 * 24 * 60 * 60 * 1000;
export const PAGINATION_LIMIT = 10;
export const ALL = 'ALL';
export const LOGIN_SUBJECT = {
  WALLET: 'WALLET',
  ACCOUNT: 'ACCOUNT',
};

export const FILTER_TYPE = {
  SELECT: 'SELECT',
  DATE_RANGE: 'DATERANGE',
  TEXT_FIELD: 'TEXT_FIELD',
  SWITCH: 'SWITCH',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
};

export const STATUS_USER = {
  DEACTIVE: '0',
  ACTIVE: 1,
};

export const GRANT_TYPE = {
  CLIENT_CREDENTIAL: 'client_credentials',
  AUTHORIZATION_CODE: 'authorization_code',
  REFRESH_TOKEN: 'refresh_token',
  PASSWORD: 'password',
};

export const RESPONSE_TYPE = {
  CODE: 'code',
};

export const REGEX_EMAIL =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

export const API_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const LOGIN_PROVIDER = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};

export {
  ROUTES,
  DATE_TIME_PICKER_TYPES,
  PERMISSION_TYPE,
  SERVICE,
  ERROR_CODE_SYSTEM,
  ERROR_CODE_PORTAL_SERVICE,
  ERROR_CODE_PAYMENT_SERVICE,
  ERROR_CODE_CALL_SERVICE,
  ERROR_CODE_PUBLIC_SERVICE,
  HOTLINE_TYPE,
  HOTLINE_STATUS,
  MOBILE_NETWORK_OPERATOR,
  LOGIN_PROVIDER,
};
