import { Button, Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import StyledNodeFound from './index.style';

const NotFound = () => {
  const history = useHistory();

  return (
    <StyledNodeFound>
      <Box>
        <Box>
          <img alt="404" src="/img/404.png" />
          <Typography mt={3} className="opp">
            OPPS!
          </Typography>
          <Typography className="not-found-text">PAGE NOT FOUND</Typography>
        </Box>
        <Button
          onClick={() => history.goBack()}
          className="go-back-button"
          variant="contained"
        >
          Go Back
        </Button>
      </Box>
    </StyledNodeFound>
  );
};

export default NotFound;
