import React, { useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import apis from '@src/apis';
import AdminManagement from '@src/components/AdminManagement';
import AdminForm from '@src/components/AdminForm';
import CustomChip from '@src/components/CustomChip';

import { usePaginationWithState } from '@src/hooks';
import { FILTER_TYPE } from '@src/constants';

import ListRoleStyle from './index.style';

const ListRole = () => {
  const { t } = useTranslation(['role']);

  const {
    data: listRoles,
    handleCallApi: fetchListRoles,
    onParamsChange,
    searchParams,
    currentPage,
    total,
    totalPage,
    limit,
    loading,
    onPaginationChange,
  } = usePaginationWithState([], apis.role.getListRoles);

  const [formUpdateFields, setFormUpdateFields] = useState({
    name: '',
    isDefault: false,
  });
  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [formCreateFields, setFormCreateFields] = useState({
    name: '',
    isDefault: false,
  });
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [errorFormCreate, setErrorFormCreate] = useState({});
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [formDeleteFields, setFormDeleteFields] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const showFormUpdateItem = (item) => {
    setFormUpdateFields(
      camelcaseKeys(
        {
          ...formUpdateFields,
          ...item,
        },
        { deep: false },
      ),
    );
    setShowFormUpdate(true);
  };

  const showFormDeleteItem = (item) => {
    setShowFormDelete(true);
    setFormDeleteFields(item);
  };

  const showFormCreateItem = () => {
    setShowFormCreate(true);
    setErrorFormCreate({});
    setFormCreateFields({
      name: '',
      isDefault: false,
    });
  };

  const actions = [
    {
      icon: <EditIcon />,
      onClick: (item) => showFormUpdateItem(item),
    },
    {
      icon: <DeleteIcon className="delete-icon" />,
      onClick: (item) => showFormDeleteItem(item),
    },
  ];

  const roleHeaders = [
    {
      label: t('n.o'),
      valueName: 'no',
      align: 'left',
    },
    {
      label: t('roleName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('default'),
      valueName: 'customIsDefault',
      align: 'left',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  const validateFormCreate = () => {
    const { name } = formCreateFields;

    let errorApp = {};

    if (!name) {
      errorApp.name = 'fieldRequired';
    }

    errorApp = { ...errorFormCreate, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrorFormCreate(errorApp);
      return false;
    }

    return true;
  };

  const handleConfirmCreate = async () => {
    if (!validateFormCreate()) return;

    try {
      const res = await apis.role.createRole(formCreateFields);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('createRoleSuccess'), { variant: 'success' });
      fetchListRoles(searchParams);
      setShowFormCreate(false);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmUpdate = () => {
    setShowFormUpdate(false);
  };

  const handleConfirmUpdate = async ({ id, name, isDefault }) => {
    try {
      const res = await apis.role.updateRole(id, {
        name,
        isDefault: !!isDefault,
      });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('updateRoleSuccess'), { variant: 'success' });
      fetchListRoles(searchParams);
      handleCloseConfirmUpdate();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setShowFormDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (!formDeleteFields || !formDeleteFields.id)
        throw new Error('invalidValue');

      const res = await apis.role.deleteRole(formDeleteFields.id);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('deleteRoleSuccess'), { variant: 'success' });
      fetchListRoles(searchParams);
      handleCloseConfirmDelete();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  return (
    <ListRoleStyle>
      <AdminManagement
        items={listRoles.map((item) => ({
          ...item,
          customIsDefault: item.isDefault ? (
            <CustomChip label={t('yes')} color="success" />
          ) : (
            <CustomChip label={t('no')} color="error" />
          ),
        }))}
        headers={roleHeaders}
        actions={actions}
        onFetchData={fetchListRoles}
        onParamsChange={onParamsChange}
        searchParams={searchParams}
        loading={loading}
        pagination={{
          page: currentPage,
          totalPages: totalPage,
          limit,
          total,
        }}
        onChangePagination={onPaginationChange}
        onCreateItem={showFormCreateItem}
      />
      {/* create role */}
      <AdminForm
        open={showFormCreate}
        onClose={() => setShowFormCreate(false)}
        onOk={handleConfirmCreate}
        okMessage={t('create')}
        title={t('createRole')}
        fields={[
          {
            field: 'name',
            label: t('roleName'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
          {
            field: 'isDefault',
            label: t('default'),
            type: FILTER_TYPE.SWITCH,
          },
        ]}
        formData={formCreateFields}
        setFormData={setFormCreateFields}
        errors={errorFormCreate}
        setErrors={setErrorFormCreate}
      />
      {/* update role */}
      <AdminForm
        open={showFormUpdate}
        onClose={handleCloseConfirmUpdate}
        onOk={handleConfirmUpdate}
        okMessage={t('update')}
        title={t('updateRole')}
        fields={[
          {
            field: 'name',
            label: t('roleName'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
          {
            field: 'isDefault',
            label: t('default'),
            type: FILTER_TYPE.SWITCH,
          },
        ]}
        formData={formUpdateFields}
        setFormData={setFormUpdateFields}
      />

      {/* delete role */}
      <AdminForm
        open={showFormDelete}
        onClose={handleCloseConfirmDelete}
        onOk={handleConfirmDelete}
        okMessage={t('delete')}
        title={t('confirmDeleteRole')}
        fields={[]}
        formData={formDeleteFields}
      />
    </ListRoleStyle>
  );
};

export default ListRole;
