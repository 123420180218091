import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

import StyledSuccessInfo from './index.style';

const SuccessInfo = ({ message, title = '', children }) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledSuccessInfo>
      <Box>
        <img className="success-image" src="/img/bg-login.png" alt="" />
      </Box>
      <Box className="success-body">
        <Paper elevation={3} className="success-info">
          <div>
            <CheckCircleIcon
              className="success-icon"
              sx={{ fontSize: '4.5rem', color: 'red' }}
            />
          </div>
          <Box className="success-box">
            <Typography mt={3} className="success-title">
              {title || t('congratulation')}
            </Typography>
            <Typography mt={3} className="success-description">
              {message}
            </Typography>
          </Box>
        </Paper>
        {children}
      </Box>
    </StyledSuccessInfo>
  );
};

export default SuccessInfo;
