/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomListRole from '@src/components/CustomListRole';
import AdminForm from '@src/components/AdminForm';
import apis from '@src/apis';
import { FILTER_TYPE } from '@src/constants';
import ListPermission from './ListPermission';
import { StyledRole } from './index.style';
import {
  groupPermissions as groupPermissionsData,
  roles as rolesData,
} from './data';

const Role = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(['system', 'common']);

  // const [search, setSearch] = useState('');
  const [roles, setRoles] = useState();
  const [groupPermissions, setGroupPermissions] = useState();
  const [roleSelected, setRoleSelected] = useState({});
  const [permissionsChange, setPermissionsChange] = useState([]);
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [formDeleteFields, setFormDeleteFields] = useState({});
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [errorFormCreate, setErrorFormCreate] = useState({});
  const [formCreateFields, setFormCreateFields] = useState({
    name: '',
  });

  const fetchRoles = async ({ isDelete }) => {
    try {
      const res = await apis.adminRole.getListRoleAdmins();
      if (res) {
        const { status, data } = res;
        const result = rolesData;
        if (status) {
          const listRole = data;
          if (
            listRole.length > 0 &&
            (isDelete || !Object.keys(roleSelected).length)
          )
            setRoleSelected(JSON.parse(JSON.stringify(listRole[0])));
          setRoles(listRole);
        }
      }
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const fetchGroupPermissions = async () => {
    try {
      const res = await apis.permission.getListPermissions();
      if (res) {
        const { status, data } = res;
        if (status) {
          setGroupPermissions(data);
        }
      }
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const validateFormCreate = () => {
    const { name } = formCreateFields;

    let errorApp = {};

    if (!name) {
      errorApp.name = 'fieldRequired';
    }

    errorApp = { ...errorFormCreate, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrorFormCreate(errorApp);
      return false;
    }

    return true;
  };

  const handleConfirmCreate = async () => {
    if (!validateFormCreate()) return;

    const { name } = formCreateFields;
    try {
      const res = await apis.adminRole.createAdminRole({
        ...formCreateFields,
        name,
      });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('createRoleSuccess'), { variant: 'success' });
      setShowFormCreate(false);
      await fetchRoles({});
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setShowFormDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await apis.adminRole.deleteRoleAdmin(roleSelected.id);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('deleteRoleSuccess'), { variant: 'success' });
      await fetchRoles({ isDelete: true });
      handleCloseConfirmDelete();
    } catch (error) {
      enqueueSnackbar(t('deleteRoleFail'), { variant: 'error' });
    }
  };

  const handleSelectPermission = (permission) => {
    let newPermissions = roleSelected.permissions;

    // unselect permission
    if (
      roleSelected.permissions.find(
        (pm) =>
          pm.method === permission.method &&
          pm.resource === permission.resource,
      )
    ) {
      newPermissions = newPermissions.filter(
        (pm) =>
          pm.method !== permission.method ||
          pm.resource !== permission.resource,
      );
    } else {
      newPermissions.push(permission);
    }

    setRoleSelected({
      ...roleSelected,
      permissions: newPermissions,
    });

    let newPermissionsChange = permissionsChange;
    if (
      permissionsChange.find(
        (pm) =>
          pm.method === permission.method &&
          pm.resource === permission.resource,
      )
    ) {
      newPermissionsChange = newPermissionsChange.filter(
        (pm) =>
          pm.method !== permission.method ||
          pm.resource !== permission.resource,
      );
    } else {
      newPermissionsChange.push(permission);
    }

    setPermissionsChange(newPermissionsChange);
  };

  const handleSelectAllPermission = (isCheckedAll, permissions) => {
    const { permissions: permissionsSelected } = roleSelected;
    let newPermissions = [];
    if (isCheckedAll) {
      newPermissions = [...permissionsSelected];
      permissions.forEach((pm) => {
        if (
          !permissionsSelected.find(
            ({ method, resource }) =>
              pm.method === method && pm.resource === resource,
          )
        ) {
          newPermissions.push({ ...pm, checked: true });
        }
      });
    } else {
      newPermissions = permissionsSelected.filter(
        (pm) =>
          !permissions.find(
            ({ method, resource }) =>
              pm.method === method && pm.resource === resource,
          ),
      );
    }

    setRoleSelected({
      ...roleSelected,
      permissions: newPermissions,
    });
  };

  const handleSelectRole = (value) => {
    setRoleSelected(JSON.parse(JSON.stringify(value)));
    setPermissionsChange([]);
  };

  const showFormCreateItem = () => {
    setShowFormCreate(true);
    setFormCreateFields({
      name: '',
    });
  };

  const handleDeleteRole = async () => {
    setShowFormDelete(true);
    setFormDeleteFields(roleSelected);
    setPermissionsChange([]);
  };

  const handleSave = async () => {
    try {
      const { id: roleId, permissions: listPermission } = roleSelected;
      const preUpdate = roles.find((item) => item.id === roleId);
      const permissionUpdate = [];

      const check = {};
      listPermission.forEach((pm) => {
        check[`${pm.method}_${pm.resource}`] = true;
        permissionUpdate.push({ ...pm, isAllowed: true });
      });

      preUpdate.permissions.forEach((pm) => {
        if (!check[`${pm.method}_${pm.resource}`]) {
          permissionUpdate.push({ ...pm, isAllowed: false });
        }
      });

      const res = await apis.adminRole.updateAdminRolePermission(roleId, {
        permissions: permissionUpdate.map(
          ({ method, resource, isAllowed }) => ({
            method,
            resource,
            is_allowed: isAllowed,
          }),
        ),
      });
      if (!res) throw new Error('serverError');
      enqueueSnackbar(t('updateSuccess'), { variant: 'success' });
      await fetchRoles({});
      setPermissionsChange([]);
    } catch (error) {
      enqueueSnackbar(t('updateFail'), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchRoles({});
    fetchGroupPermissions();
  }, []);

  if (!roles || !groupPermissions) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <StyledRole>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={3}
        mt={4}
      >
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="secondary"
            className="btn-normal btn-delete-role"
            onClick={handleDeleteRole}
          >
            {t('deleteRole')}
          </Button>
          <Button
            variant="outlined"
            className="btn-normal"
            onClick={handleSave}
          >
            {t('saveChange')}
          </Button>
        </Box>
      </Box>
      <Box className="content-container">
        <Grid container>
          <Grid item xs={3}>
            <Box className="list-container">
              <Box className="list-role">
                <CustomListRole
                  items={roles}
                  itemSelected={roleSelected}
                  onSelectItem={handleSelectRole}
                />
              </Box>
              <Box className="box-btn-add">
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<AddCircleIcon />}
                  className="btn-normal"
                  onClick={showFormCreateItem}
                >
                  {t('addRole')}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box className="list-permission">
              {groupPermissions.map((el) => (
                <ListPermission
                  key={el.id}
                  title={t(el.name) || t('common:::other')}
                  permissions={
                    el.permissions &&
                    el.permissions.map((permission) => {
                      if (
                        roleSelected.permissions &&
                        roleSelected.permissions.find(
                          (pm) =>
                            pm.method === permission.method &&
                            pm.resource === permission.resource,
                        )
                      ) {
                        return { ...permission, checked: true };
                      }
                      return { ...permission, checked: false };
                    })
                  }
                  onSelectPermission={handleSelectPermission}
                  onSelectAllPermission={handleSelectAllPermission}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AdminForm
        open={showFormCreate}
        onClose={() => setShowFormCreate(false)}
        onOk={handleConfirmCreate}
        okMessage={t('create')}
        title={t('addRole')}
        fields={[
          {
            field: 'name',
            label: t('roleName'),
            type: FILTER_TYPE.TEXT_FIELD,
            defaultValue: '',
          },
        ]}
        formData={formCreateFields}
        setFormData={setFormCreateFields}
        errors={errorFormCreate}
        setErrors={setErrorFormCreate}
      />
      <AdminForm
        open={showFormDelete}
        onClose={handleCloseConfirmDelete}
        onOk={handleConfirmDelete}
        okMessage={t('delete')}
        title={t('common:::confirmQuestion')}
        fields={[]}
        formData={formDeleteFields}
      />
    </StyledRole>
  );
};

export default Role;
