/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ROUTES, API_METHOD } from '@src/constants';

import apis from '@src/apis';
import { getCookie } from '@src/utils/cookie';
import { routes as appRoutes } from './appRoutes';
import actions from '../redux/actions';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { accessToken, verified } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isPermission, setIsPermission] = useState(null);

  const verifyTokenFromCookie = () => {
    const tokenFromCookie = getCookie('accessToken');
    console.log({ tokenFromCookie });
    if (tokenFromCookie) {
      dispatch(actions.auth.verifyUser(tokenFromCookie));
      return;
    }

    if (!verified) {
      history.push(ROUTES.LOGIN);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      verifyTokenFromCookie();
    }
  }, [accessToken, verified, dispatch]);

  const checkUserPermission = async () => {
    if (!path) {
      setIsPermission(true);
      return;
    }

    const route = appRoutes
      .filter((el) => el.isMenu)
      .find((menu) => path.indexOf(menu.path) >= 0);

    if (route) {
      try {
        const res = await apis.admin.checkAdminPermission({
          method: API_METHOD.GET,
          resource: route.path,
        });
        if (res.status && res.data.allowed) {
          setIsPermission(true);
          return;
        }
      } catch (error) {
        enqueueSnackbar(t(error.message), {
          variant: 'error',
        });
      }
      setIsPermission(false);
    }
  };

  useEffect(() => {
    if (verified) {
      checkUserPermission();
    }
  }, [verified]);

  if (!verified) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          if (isPermission === false)
            return <Redirect to={ROUTES.UNAUTHORIZED} />;

          return <Component {...props} />;
        }
        return <Redirect to={ROUTES.LOGIN} />;
      }}
    />
  );
};

export default PrivateRoute;
