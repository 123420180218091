import React from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// import CustomPermissionTypeTag from '@src/components/CustomPermissionTypeTag';
import { StyledListPermission } from './index.style';

const ListPermission = ({
  title,
  permissions = [],
  onSelectPermission,
  onSelectAllPermission,
}) => (
  <StyledListPermission>
    <Grid container spacing={2}>
      <FormControlLabel
        className="form-control-parent"
        label={<Typography className="header-title">{title}</Typography>}
        control={
          <Checkbox
            checked={permissions.every(({ checked }) => checked)}
            indeterminate={
              permissions.some(({ checked }) => checked) &&
              permissions.some(({ checked }) => !checked)
            }
            onChange={(e) =>
              onSelectAllPermission(e.target.checked, permissions)
            }
          />
        }
      />
    </Grid>
    <Grid container spacing={2}>
      {permissions.map((permission) => (
        <Grid key={permission.id} item xs={6} className="item">
          <FormControlLabel
            control={<Checkbox checked={permission.checked} />}
            label={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                {/* <CustomPermissionTypeTag type={permission.type} /> */}
                <Typography fontWeight="500" minWidth="45px">
                  {permission.method}
                </Typography>
                <Typography>{permission.resource}</Typography>
              </Box>
            }
            className="checkbox"
            onChange={() => onSelectPermission(permission)}
          />
        </Grid>
      ))}
    </Grid>
  </StyledListPermission>
);

export default ListPermission;
