import { put, all, takeLatest } from 'redux-saga/effects';
import apis from '@src/apis';
import { setCookie } from '@src/utils/cookie';
import { A_WEEK } from '@src/constants';
import axiosClient from '@src/apis/api';

import actions from '../actions';

function* verifyUserSaga({ accessToken }) {
  try {
    const { status } = yield apis.auth.verifyToken(accessToken);
    if (status) yield put(actions.auth.verifyUserSuccess(accessToken));
  } catch (error) {
    setCookie('accessToken', '');
    yield put(
      actions.noti.push({
        severity: 'error',
        message: 'token invalid',
      }),
    );
  }
}

function* loginSaga(action) {
  const { payload } = action;
  const { email, password } = payload;
  try {
    const { accessToken } = yield apis.auth.login(email, password);
    axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setCookie('accessToken', accessToken, A_WEEK);
    yield put(actions.auth.loginSuccess(accessToken));
    yield put(
      actions.noti.push({ message: 'loginSuccess', severity: 'success' }),
    );
  } catch (error) {
    yield put(actions.noti.push({ message: 'loginFailed', severity: 'error' }));
    yield put(actions.auth.loginFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.auth.actionTypes.VERIFY_USER, verifyUserSaga),
    takeLatest(actions.auth.actionTypes.LOGIN, loginSaga),
  ]);
}
