import styled from 'styled-components';

export default styled.div`
  .scaptcha-anchor-container {
    border-radius: 3px;
  }
  .scaptcha-card-container {
    z-index: 1 !important;
  }
  .scaptcha-anchor-checkbox {
    border: 1px solid #424242;
  }
  .scaptcha-anchor-label {
    font-size: 14px;
    font-weight: 500;
  }
  .scaptcha-card-slider-control-active {
    background: #99efd0;
  }
  .scaptcha-card-slider-track-active {
    background: #99efd0;
  }
  .scaptcha-card-slider-control-success {
    background: #13c296;
  }
  .scaptcha-card-slider-track-success {
    background: #13c296;
  }
`;
