import api from './api';

export const getListRoleAdmins = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/role-admins',
    params,
  });
  return res;
};

export const createAdminRole = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/api/admin/role-admins',
    data,
  });
  return res;
};

export const updateAdminRolePermission = async (roleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/api/admin/role-admins/${roleId}/permissions`,
    data,
  });
  return res;
};

export const deleteRoleAdmin = async (roleId) => {
  const res = await api({
    method: 'DELETE',
    url: `/api/admin/role-admins/${roleId}`,
  });
  return res;
};
