import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCustomListRole = styled('div')`
  .item {
    padding: 8px 18px;
    margin: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f4f4f4;
    }

    .title: {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: ${COLOR.dark};
    }

    .description {
      font-size: 14px;
      line-height: 21px;
      color: ${COLOR.bodyText};
    }
  }

  .item-selected {
    border-left: 3px solid ${COLOR.primary};

    .title {
      color: ${COLOR.primary};
    }
  }
`;
