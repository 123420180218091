import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledLogin = styled(Box)`
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin-top: 100px;
  .title {
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .text-field {
    display: block;
    margin-top: 20px;
    input {
      width: 400px;
    }
  }
  .login-button {
    width: 100%;
    margin-top: 20px;
  }
`;
