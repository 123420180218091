import React, { useState } from 'react';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import StyledVisibilityInput from './index.style';

const VisibilityInput = ({ value }) => {
  const [showValue, setShowValue] = useState(false);

  const handleClickShowValue = () => {
    setShowValue(!showValue);
  };

  const handleMouseDownValue = (event) => {
    event.preventDefault();
  };

  return (
    <StyledVisibilityInput>
      <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-password"
          className="outlined-input"
          type={showValue ? 'text' : 'password'}
          value={value}
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowValue}
                onMouseDown={handleMouseDownValue}
                edge="end"
              >
                {showValue ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </StyledVisibilityInput>
  );
};

export default VisibilityInput;
