import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import apis from '@src/apis';
import AdminManagement from '@src/components/AdminManagement';
import AdminForm from '@src/components/AdminForm';
import { usePaginationWithState } from '@src/hooks';

import { FILTER_TYPE } from '@src/constants';

import UserRoleStyled from './index.style';

const UserRole = () => {
  const params = useParams();
  const { t } = useTranslation(['user', 'client', 'role']);
  const { enqueueSnackbar } = useSnackbar();

  const [showFormCreate, setShowFormCreate] = useState(false);
  const [formCreateFields, setFormCreateFields] = useState({
    clientId: '',
    roleId: '',
    userId: params.userId,
  });

  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [formUpdateFields, setFormUpdateFields] = useState({
    clientId: '',
    roleId: '',
    userId: params.userId,
  });

  const [showFormDelete, setShowFormDelete] = useState(false);
  const [formDeleteFields, setFormDeleteFields] = useState({});

  const [hiddenSelectRole, setHiddenSelectRole] = useState(true);
  const [errorFormCreate, setErrorFormCreate] = useState({});

  const {
    data: listUserRoles,
    handleCallApi: fetchListUserRoles,
    currentPage,
    total,
    totalPage,
    limit,
    loading,
    onPaginationChange,
    onParamsChange,
    searchParams,
  } = usePaginationWithState([], apis.userRole.getListUserRoles, true, {
    userId: params.userId,
  });

  const { data: listRoles } = usePaginationWithState(
    [],
    apis.role.getListRoles,
    true,
    {
      limit: 100,
    },
  );

  const showFormCreateItem = () => {
    setShowFormCreate(true);
    setHiddenSelectRole(true);
    setFormCreateFields({
      clientId: '',
      roleId: '',
      userId: params.userId,
      customClient: {
        value: '',
        label: '',
      },
    });
  };

  const validateFormCreate = () => {
    const { roleId, clientId } = formCreateFields;

    let errorApp = {};

    if (!roleId) {
      errorApp.roleId = 'fieldRequired';
    }

    if (!clientId) {
      errorApp.clientId = 'fieldRequired';
    }

    errorApp = { ...errorFormCreate, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrorFormCreate(errorApp);
      return false;
    }

    return true;
  };

  const handleConfirmCreate = async (formData) => {
    if (!validateFormCreate()) return;
    const { userId, roleId, clientId } = formData;
    try {
      const res = await apis.userRole.updateUserRole(
        { userId, clientId },
        { roleId },
      );
      if (!res) throw new Error('serverError');
      fetchListUserRoles(searchParams);
      setShowFormCreate(false);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const showFormUpdateItem = (item) => {
    setFormUpdateFields({
      ...item,
      customClient: {
        ...item.client,
        value: item.client.value,
        label: item.client.name,
      },
    });
    setShowFormUpdate(true);
  };

  const showFormDeleteItem = (item) => {
    setShowFormDelete(true);
    setFormDeleteFields(item);
  };

  const onFetchClients = (search) => apis.client.getListClients({ search });

  const handleChangeClientFormCreate = (client) => {
    setFormCreateFields((current) => ({
      ...current,
      clientId: client.id,
      customClient: client,
    }));
    if (client.id) setHiddenSelectRole(false);
  };

  const handleCloseConfirmUpdate = () => {
    setShowFormUpdate(false);
    setFormUpdateFields({
      clientId: '',
      roleId: '',
      userId: params.userId,
    });
  };

  const handleConfirmUpdate = async (formData) => {
    const {
      userId,
      customClient: { id: clientId },
      roleId,
    } = formData;
    try {
      const res = await apis.userRole.updateUserRole(
        { userId, clientId },
        { roleId },
      );
      if (!res) throw new Error('serverError');
      fetchListUserRoles(searchParams);
      setShowFormUpdate(false);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setShowFormDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      if (
        !formDeleteFields ||
        !formDeleteFields.userId ||
        !formDeleteFields.clientId ||
        !formDeleteFields.roleId
      )
        throw new Error('invalidValue');

      const res = await apis.userRole.deleteUserRole({
        userId: formDeleteFields.userId,
        clientId: formDeleteFields.clientId,
      });
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('deleteRoleSuccess'), { variant: 'success' });
      fetchListUserRoles(searchParams);
      handleCloseConfirmDelete();
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const actions = [
    {
      key: 'edit',
      icon: <EditIcon />,
      onClick: (item) => showFormUpdateItem(item),
    },
    {
      key: 'delete',
      icon: <DeleteIcon className="delete-icon" />,
      onClick: (item) => showFormDeleteItem(item),
    },
  ];

  const userRoleHeaders = [
    {
      label: t('no'),
      valueName: 'no',
      align: 'left',
    },
    {
      label: t('client:::clientName'),
      valueName: 'clientName',
      align: 'left',
    },
    {
      label: t('role:::roleName'),
      valueName: 'roleName',
      align: 'left',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  return (
    <UserRoleStyled>
      <AdminManagement
        items={listUserRoles.map((item) => ({
          ...item,
          clientName: item.client.name,
          roleName: item.role.name,
        }))}
        headers={userRoleHeaders}
        actions={actions}
        onFetchData={fetchListUserRoles}
        onParamsChange={onParamsChange}
        searchParams={searchParams}
        loading={loading}
        pagination={{
          page: currentPage,
          totalPages: totalPage,
          limit,
          total,
        }}
        onChangePagination={onPaginationChange}
        onCreateItem={showFormCreateItem}
      />

      {/* create user */}
      <AdminForm
        open={showFormCreate}
        onClose={() => setShowFormCreate(false)}
        onOk={handleConfirmCreate}
        okMessage={t('create')}
        title={t('createUserRole')}
        fields={[
          {
            field: 'customClient',
            label: t('client'),
            type: FILTER_TYPE.AUTOCOMPLETE,
            options: [],
            onFetchData: onFetchClients,
            onChange: handleChangeClientFormCreate,
          },
          {
            field: 'roleId',
            label: t('role'),
            type: FILTER_TYPE.SELECT,
            options: listRoles.map((item) => ({
              ...item,
              value: item.id,
              label: item.name,
            })),
            hidden: hiddenSelectRole,
          },
        ]}
        formData={formCreateFields}
        setFormData={setFormCreateFields}
        errors={errorFormCreate}
        setErrors={setErrorFormCreate}
      />

      {/* update user */}
      <AdminForm
        open={showFormUpdate}
        onClose={handleCloseConfirmUpdate}
        onOk={handleConfirmUpdate}
        okMessage={t('update')}
        title={t('updateUser')}
        fields={[
          {
            field: 'customClient',
            label: t('clientId'),
            type: FILTER_TYPE.AUTOCOMPLETE,
            options: [],
            onFetchData: onFetchClients,
          },
          {
            field: 'roleId',
            label: t('role'),
            type: FILTER_TYPE.SELECT,
            options: listRoles.map((item) => ({
              ...item,
              value: item.id,
              label: item.name,
            })),
          },
        ]}
        formData={formUpdateFields}
        setFormData={setFormUpdateFields}
      />

      {/* delete role */}
      <AdminForm
        open={showFormDelete}
        onClose={handleCloseConfirmDelete}
        onOk={handleConfirmDelete}
        okMessage={t('common:::confirm')}
        title={t('common:::confirmQuestion')}
        fields={[]}
        formData={formDeleteFields}
      />
    </UserRoleStyled>
  );
};

export default UserRole;
