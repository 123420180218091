import snakecaseKeys from 'snakecase-keys';

import api from './api';

export const getListAdmins = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/admins',
    params,
  });
  return res;
};

export const updateAdmin = async (adminId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/api/admin/admins/${adminId}`,
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const createAdmin = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/api/admin/admins',
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const deleteAdmin = async (adminId) => {
  const res = await api({
    method: 'DELETE',
    url: `/api/admin/admins/${adminId}`,
  });
  return res;
};

export const checkAdminPermission = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/admins/check-permission',
    params,
  });
  return res;
};
