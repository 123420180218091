/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ROUTES from '@src/constants/route';
import { Icon } from '@mui/material';

export const sidebarMenu = [
  {
    key: 'UsersManagement',
    heading: 'userManagement',
    icon: <Icon>supervisor_account</Icon>,
    route: ROUTES.USERS,
    role: ['user'],
  },
  {
    key: 'RoleManagement',
    heading: 'roleManagement',
    icon: <Icon>manage_accounts</Icon>,
    route: ROUTES.ROLES,
    role: ['user'],
  },
  {
    key: 'ClientManagement',
    heading: 'clientManagement',
    icon: <Icon>devices</Icon>,
    route: ROUTES.CLIENTS,
    role: ['user'],
  },
  {
    key: 'AdminManagement',
    heading: 'adminManagement',
    icon: <Icon>admin_panel_settings</Icon>,
    route: ROUTES.ADMINS,
    role: ['user'],
  },
  {
    key: 'System',
    heading: 'system',
    icon: <Icon>verified_user</Icon>,
    role: ['user'],
    subMenu: [
      {
        key: 'Permission',
        heading: 'adminPermission',
        route: ROUTES.ADMIN_PERMISSION,
        role: ['user'],
      },
    ],
  },
  // {
  //   key: "CustomerInsight",
  //   heading: "Customer Insight",
  //   icon: <Icon>insights</Icon>,
  //   role: ["user"],
  //   subMenu: [
  //     {
  //       key: "CRM",
  //       heading: "CRM",
  //       route: ROUTES.BUSINESS_ANALYTIC_DASHBOARD,
  //       role: ["user"],
  //     },
  //     {
  //       key: "CustomerAnalytics",
  //       heading: "Customer Analytics",
  //       route: ROUTES.SALE_ANALYTICS,
  //       role: ["user"],
  //     },
  //   ],
  // },
];
