import ROUTES from '@src/constants/route';
import Login from '@src/pages/Login/index';
import LoginSSO from '@src/pages/LoginSSO/index';
import RegisterSSO from '@src/pages/RegisterSSO/index';
import ListRole from '@src/pages/Role/ListRole';
import ListUser from '@src/pages/User/ListUser';
import ListClient from '@src/pages/Client/ListClient';
import ListAdmin from '@src/pages/Admin/ListAdmin';
import UserRole from '@src/pages/User/UserRole';
import AdminPermission from '@src/pages/AdminPermission';
import AdminRoleContainer from '@src/pages/AdminRole';
import Unauthorized from '@src/pages/Unauthorized';
import NotFound from '@src/containers/NotFound';
import ForgotPassword from '@src/pages/ForgotPassword';
import ResetPassword from '@src/pages/ResetPassword';

const routes = [
  {
    path: ROUTES.UNAUTHORIZED,
    component: Unauthorized,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: false,
    ignoreToken: true,
    isAdmin: true,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
    exact: true,
    restricted: true,
    isPrivate: false,
    isAdmin: true,
  },
  {
    path: ROUTES.LOGIN_SSO,
    component: LoginSSO,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: ROUTES.REGISTER_SSO,
    component: RegisterSSO,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: ROUTES.NOT_FOUND,
    component: NotFound,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: ROUTES.ROLES,
    component: ListRole,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: ROUTES.USERS,
    component: ListUser,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: `${ROUTES.USERS}/:userId`,
    component: UserRole,
    exact: true,
    restricted: false,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: ROUTES.CLIENTS,
    component: ListClient,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: ROUTES.ADMINS,
    component: ListAdmin,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: ROUTES.ADMIN_PERMISSION,
    component: AdminPermission,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: ROUTES.ADMIN_ROLE,
    component: AdminRoleContainer,
    exact: true,
    restricted: false,
    isPrivate: true,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
    exact: true,
    restricted: false,
    isPrivate: false,
    isMenu: false,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
    restricted: false,
    isPrivate: false,
    isMenu: false,
  },
];

const adminRoutes = routes.filter((route) => route.isAdmin);
const otherRoutes = routes.filter((route) => !route.isAdmin);

export { routes, adminRoutes, otherRoutes };
