import snakecaseKeys from 'snakecase-keys';

import api from './api';

export const getListClients = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/clients',
    params,
  });
  return res;
};

export const updateClient = async (clientId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/api/admin/clients/${clientId}`,
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const createClient = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/api/admin/clients',
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const deleteClient = async (clientId) => {
  const res = await api({
    method: 'DELETE',
    url: `/api/admin/clients/${clientId}`,
  });
  return res;
};
