import React, { useState } from 'react';
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  Box,
  Grid,
  Slide,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

import { FILTER_TYPE } from '@src/constants';

import CustomSelect from '@src/components/CustomSelect';
import CustomSwitch from '@src/components/CustomSwitch';

import { AdminFormStyle } from './index.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AdminForm = (props) => {
  const { t } = useTranslation(['common']);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [completeOptions, setCompleteOptions] = useState([]);
  const [completeLoading, setCompleteLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    open,
    onOk,
    onClose,
    title = t('note'),
    okMessage = t('confirm'),
    cancelMessage = t('cancel'),
    fields = [],
    formData = {},
    setFormData,
    errors = {},
    setErrors,
    width,
  } = props;

  const handleOk = () => {
    if (setErrors) setErrors({});
    onOk(formData);
  };

  const handleSelectChange = (item, value) => {
    setFormData({ ...formData, [item.field]: value || `${value}` });

    if (setErrors)
      setErrors((prev) => ({
        ...prev,
        [item.field]: '',
      }));

    if (item.onChange) item.onChange(value);
  };

  const handleMultipleSelectChange = (item, event) => {
    const {
      target: { value },
    } = event;

    setFormData({
      ...formData,
      [item.field]: typeof value === 'string' ? value.split(',') : value,
    });

    if (setErrors)
      setErrors((prev) => ({
        ...prev,
        [item.field]: '',
      }));
  };

  const handleChangeTextField = (item, e) => {
    setFormData({ ...formData, [item.field]: e.target.value });

    if (setErrors)
      setErrors((prev) => ({
        ...prev,
        [item.field]: '',
      }));
  };

  const handleChangeSwitch = (item, value) => {
    setFormData({ ...formData, [item.field]: value });
  };

  const onChangeHandleInputAutocomplete = async (item, value) => {
    setCompleteLoading(true);
    try {
      const { onFetchData } = item;
      if (!onFetchData) throw new Error('serverError');

      const res = await onFetchData(value);
      if (!res) throw new Error('serverError');

      setCompleteOptions(
        res.data.data.map((row) => ({
          ...row,
          value: row.id,
          label: row.name,
        })),
      );
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
    setCompleteLoading(false);
    return true;
  };

  const handleSelectAutocomplete = (item, value) => {
    setFormData({ ...formData, [item.field]: value });

    if (item.onChange) item.onChange(value);

    if (setErrors)
      setErrors((prev) => ({
        ...prev,
        [item.field]: '',
      }));
  };

  const renderItemForm = (item) => {
    switch (item.type) {
      case FILTER_TYPE.TEXT_FIELD:
        return (
          <Box className="item" key={JSON.stringify(item)}>
            <Grid container spacing={2}>
              <Grid item sm={4} md={4} className="title">
                <Typography className="title">{item.label}</Typography>
              </Grid>
              <Grid item sm={8} md={8}>
                <TextField
                  id="outlined-disabled"
                  label=""
                  size="small"
                  defaultValue={item.defaultValue}
                  value={formData[item.field]}
                  disabled={item.disabled}
                  error={Boolean(errors[item.field])}
                  type={item.typeInput || 'text'}
                  helperText={
                    errors[item.field] && t(`common:::${errors[item.field]}`)
                  }
                  onChange={(e) => handleChangeTextField(item, e)}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case FILTER_TYPE.SELECT:
        return (
          !item.hidden && (
            <Box className="item" key={JSON.stringify(item)}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} className="title">
                  <Typography className="title">{item.label}</Typography>
                </Grid>
                <Grid item sm={8} md={8}>
                  <CustomSelect
                    key={item.type}
                    placeholder={item.placeholder}
                    options={item.options}
                    onChange={(value) => handleSelectChange(item, value)}
                    helperText={
                      errors[item.field] && t(`common:::${errors[item.field]}`)
                    }
                    error={Boolean(errors[item.field])}
                    value={formData[item.field]}
                    multiple={item.multiple}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        );
      case FILTER_TYPE.MULTIPLE_SELECT:
        return (
          <Box className="item" key={JSON.stringify(item)}>
            <Grid container spacing={2}>
              <Grid item sm={4} md={4} className="title">
                <Typography className="title">{item.label}</Typography>
              </Grid>
              <Grid item sm={8} md={8}>
                <Select
                  key={item.type}
                  size="small"
                  placeholder={item.placeholder}
                  options={item.options}
                  onChange={(value) => handleMultipleSelectChange(item, value)}
                  value={formData[item.field]}
                  multiple={item.multiple}
                  error={Boolean(errors[item.field])}
                >
                  {item.options.map((option) => (
                    <MenuItem
                      key={`${JSON.stringify(option)}`}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(errors[item.field]) && (
                  <FormHelperText className="error_message">
                    {errors[item.field] && t(`common:::${errors[item.field]}`)}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case FILTER_TYPE.SWITCH:
        return (
          <Box className="item" key={JSON.stringify(item)}>
            <Grid container spacing={2}>
              <Grid item sm={4} md={4} className="title">
                <Typography className="title">{item.label}</Typography>
              </Grid>
              <Grid item sm={8} md={8}>
                <CustomSwitch
                  key={item.type}
                  checked={formData[item.field]}
                  onChange={(value) => handleChangeSwitch(item, value)}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case FILTER_TYPE.AUTOCOMPLETE:
        return (
          <Box className="item" key={JSON.stringify(item)}>
            <Grid container spacing={2}>
              <Grid item sm={4} md={4} className="title">
                <Typography>{item.label}</Typography>
              </Grid>
              <Grid item sm={8} md={8}>
                <Autocomplete
                  size="small"
                  freeSolo
                  key={JSON.stringify(item)}
                  open={openAutocomplete}
                  onOpen={() => {
                    setOpenAutocomplete(true);
                  }}
                  onClose={() => {
                    setOpenAutocomplete(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, value) => handleSelectAutocomplete(item, value)}
                  getOptionLabel={(option) => option.label}
                  options={completeOptions}
                  loading={completeLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      onChange={(e) => {
                        // dont fire API if the user delete or not entered anything
                        if (e.target.value !== '' || e.target.value !== null) {
                          onChangeHandleInputAutocomplete(item, e.target.value);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {completeLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  value={formData[item.field]}
                />
                {Boolean(errors[item.field]) && (
                  <FormHelperText className="error_message">
                    {errors[item.field] && t(`common:::${errors[item.field]}`)}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <AdminFormStyle
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      width={width}
    >
      <Box>
        <Box className="close-icon" display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            className="close-button"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box paddingX={3} pb={2} className="content-container">
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {fields.map((item) => renderItemForm(item))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOk} variant="contained" color="primary">
              {okMessage}
            </Button>
            <Button onClick={onClose} variant="outlined" color="primary">
              {cancelMessage}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </AdminFormStyle>
  );
};

export default AdminForm;
