import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { COLOR } from '@src/styles/color';

export const StyledLogin = styled(Box)`
  margin: 120px auto 0;
  justify-content: center;
  display: flex;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
  .title {
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .text-field {
    display: block;
    margin-top: 20px;
    .MuiInputLabel-root {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .MuiOutlinedInput-root {
      width: 300px;
      background: #101010;
    }
    input {
      border-radius: 5px;
      color: #fff;
      backdrop-filter: blur(25px);
      font-size: 14px;
    }
  }
  .login-button {
    width: 100%;
    background: linear-gradient(180deg, #ffa358 7.46%, #f07a3a 75.24%);
    height: 40px;
    font-size: 18px;
    text-transform: none;
    font-weight: 600;
  }
  .login-with-wallet {
    margin-top: 10px;
    width: 100%;
    background: #5454d4;
    box-shadow: 0px 4px 61px rgba(76, 70, 194, 0.4);
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    text-transform: none;
    font-weight: 600;
  }
  .forgot-password {
    margin-top: 10px;
    color: #b0b0b0;
    display: flex;
    justify-content: flex-end;
    p {
      cursor: pointer;
      font-size: 14px;
    }
  }
  .error_message {
    color: ${COLOR.primary};
  }
  .redirect {
    margin-bottom: 10px;
    margin-top: 5px;

    .redirect-link {
      text-decoration: none;
      color: ${COLOR.warning};
    }
  }
  .scaptcha-anchor-element {
    justify-content: center;
  }
`;

export const StyledSocialLogin = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  .facebook-button-login {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;
