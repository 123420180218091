import snakecaseKeys from 'snakecase-keys';

import { SERVICE } from '@src/constants';
import api from './api';

export const getListRoles = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/api/admin/roles',
    params,
    source: SERVICE.PORTAL,
  });
  return res;
};

export const updateRole = async (roleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/api/admin/roles/${roleId}`,
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const createRole = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/api/admin/roles',
    data: snakecaseKeys(data, { deep: true }),
  });
  return res;
};

export const deleteRole = async (roleId) => {
  const res = await api({
    method: 'DELETE',
    url: `/api/admin/roles/${roleId}`,
  });
  return res;
};
