import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const AdminFormStyle = styled(Dialog)`
  .MuiDialog-paper {
    min-width: ${(props) => props.width || '512px'};
  }

  .MuiDialogTitle-root {
    padding: 5px 24px 10px;
    color: ${COLOR.headingText};
    font-size: 21px;
    font-weight: 600;
  }

  .content-container {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .close-icon {
    padding: 2px 2px 0 0;
  }

  .cancel-button {
    color: ${COLOR.primary};
    margin-right: 20px;
  }

  .content {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${COLOR.bodyText};
  }

  .MuiDialogContent-root {
    width: 100%;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: center;

    > :not(:first-of-type) {
      margin-left: 26px;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .title {
      display: flex;
      align-items: center;
    }
  }

  .MuiTextField-root,
  .MuiSelect-root {
    width: 100%;
  }
  .error_message {
    color: ${COLOR.primary};
  }
  .MuiFormHelperText-root {
    margin-left: 0px;
  }
`;
