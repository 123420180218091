import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import {
  ROUTES,
  REGEX_EMAIL,
  LOGIN_SUBJECT,
  LOGIN_PROVIDER,
} from '@src/constants';
import { API_URL, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '@src/configs';
import apis from '@src/apis';
import ErrorInfo from '@src/components/ErrorInfo';
import CustomSliderCaptcha from '@src/components/CustomSliderCaptcha';
import { providerOptions } from './providerOptions';

import { StyledLogin, StyledSocialLogin } from './index.style';

const stylePlaceHolder = { color: '#fff', fontWeight: '600', fontSize: '14px' };

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

export default function LoginSSO() {
  const { t } = useTranslation(['user', 'common']);
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const [errors, setErrors] = useState({ captchaToken: '' });
  const [validParam, setValidParam] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingLoginWallet, setLoadingLoginWallet] = useState(false);

  const [provider, setProvider] = useState();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { client_id: clientId, redirect_uri: redirectUri } =
      queryString.parse(location.search);

    let errorMessage = '';
    if (!clientId || !redirectUri) {
      errorMessage = 'Invalid Request';
      setValidParam(false);
      setErrors({ errorMessage });
      return;
    }

    setValidParam(true);
    setErrors({ errorMessage: '' });
  }, []);

  const handleClickShowPassword = (type) => {
    setShowPassword({
      ...showPassword,
      [type]: !showPassword[type],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData({
      ...formData,
      email,
    });
    setErrors({ email: '' });
  };

  const handleChangeRecaptcha = (captchaToken) => {
    setFormData({
      ...formData,
      captchaToken,
    });
    setErrors({ captchaToken: '' });
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    setFormData({
      ...formData,
      password,
    });
    setErrors({ password: '' });
  };

  const validateForm = () => {
    const { password, email } = formData;

    let errorApp = {};
    if (!email) {
      errorApp.email = 'fieldRequired';
    }

    if (email && !String(email).toLowerCase().trim().match(REGEX_EMAIL)) {
      errorApp.email = 'emailInvalid';
    }

    if (!password) {
      errorApp.password = 'fieldRequired';
    }

    errorApp = { ...errors, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrors(errorApp);
      return false;
    }

    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!formData.sessionId || !formData.captchaToken) {
      setErrors({ captchaToken: 'fieldRequired' });
      return;
    }

    const { client_id: clientId, redirect_uri: redirectUri } =
      queryString.parse(location.search);

    setLoadingLogin(true);
    try {
      const res = await apis.oauth.loginSSO({
        client_id: clientId,
        redirect_uri: redirectUri,
        username: formData.email,
        password: formData.password,
        session_id: formData.sessionId,
        captcha_token: formData.captchaToken,
      });
      setLoadingLogin(false);
      window.location.href = `${res.request.responseURL}&from=${LOGIN_SUBJECT.ACCOUNT}`;
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }

    setFormData({ ...formData, email: '', password: '' });
    setErrors({ email: '', password: '' });
    setLoadingLogin(false);
  };

  const handleForgotPassword = () => {
    history.push(`${ROUTES.FORGOT_PASSWORD}${location.search}`);
  };

  const handleValidCaptcha = (token) => {
    handleChangeRecaptcha(token);
  };

  const handleChangeSession = (sessionId) => {
    setFormData({
      ...formData,
      sessionId,
    });
    setErrors({ captchaToken: '' });
  };

  const handleGetMessage = async ({ walletAddress, clientId }) => {
    try {
      const res = await apis.oauth.getNonces({ clientId, walletAddress });
      if (res.status) return res.data;
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
    return null;
  };

  const connectWallet = async () => {
    try {
      const instance = await web3Modal.connect();
      const lib = new ethers.providers.Web3Provider(instance);
      const accounts = await lib.listAccounts();
      setProvider(instance);

      return { library: lib, address: accounts[0] };
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSignMessage = async () => {
    const { library, address } = await connectWallet();

    try {
      const { client_id: clientId } = queryString.parse(location.search);
      // get nonce session
      const messageData = await handleGetMessage({
        walletAddress: address,
        clientId,
      });
      if (!messageData) return null;
      const { id: sessionId, nonce: message } = messageData;

      const signer = library.getSigner();
      const signature = await signer.signMessage(message);

      return {
        message,
        signature,
        address,
        sessionId,
      };
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
    return null;
  };

  const disconnect = async () => {
    if (provider && provider.close) await provider.close();

    await web3Modal.clearCachedProvider();
  };

  const handleLoginWithWallet = async () => {
    try {
      setLoadingLoginWallet(true);
      // sign message
      const signatures = await handleSignMessage();
      await disconnect();
      setLoadingLoginWallet(false);
      if (!signatures) return;

      const { signature, address, sessionId } = signatures;
      const { client_id: clientId, redirect_uri: redirectUri } =
        queryString.parse(location.search);
      // handle login get authorization code
      const res = await apis.oauth.verifySignatures({
        clientId,
        redirectUri,
        sessionId,
        signature,
        walletAddress: address,
      });

      window.location.href = res.request.responseURL;
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }

    disconnect();
    setLoadingLoginWallet(false);
  };

  const handleCallbackFbLogin = async (response) => {
    try {
      const { client_id: clientId, redirect_uri: redirectUri } =
        queryString.parse(location.search);
      // handle login get authorization code
      const res = await apis.oauth.loginSocial({
        clientId,
        redirectUri,
        provider: LOGIN_PROVIDER.FACEBOOK,
        accessToken: response.accessToken,
      });

      window.location.href = res.request.responseURL;
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleLoginGoogleSuccess = async (response) => {
    try {
      const { client_id: clientId, redirect_uri: redirectUri } =
        queryString.parse(location.search);
      // handle login get authorization code
      const res = await apis.oauth.loginSocial({
        clientId,
        redirectUri,
        provider: LOGIN_PROVIDER.GOOGLE,
        accessToken: response.tokenId,
      });

      window.location.href = res.request.responseURL;
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleLoginGoogleFail = (res) => {
    console.log('google login err: ', res);
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) connectWallet();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (provider) {
      const handleAccountsChanged = (accounts) => {};

      const handleChainChanged = (_hexChainId) => {};

      const handleDisconnect = () => {
        disconnect();
      };

      const handleResetApp = () => {
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);
      provider.on('close', handleResetApp);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
          provider.removeListener('close', handleResetApp);
        }
      };
    }
  }, [provider]);

  if (!validParam) return <ErrorInfo message={errors.errorMessage} />;

  return (
    <StyledLogin>
      <Box>
        <form onSubmit={handleLogin}>
          <Typography className="title" type="email" color="primary">
            {t('login')}
          </Typography>
          <TextField
            className="text-field"
            id="standard-input"
            // label={t('email')}
            placeholder={t('email')}
            onChange={handleEmailChange}
            InputLabelProps={{
              style: stylePlaceHolder,
            }}
            error={Boolean(errors.email)}
            helperText={errors.email && t(`common:::${errors.email}`)}
            value={formData.email}
          />
          <FormControl
            variant="outlined"
            className="text-field"
            error={Boolean(errors.password)}
          >
            {/* <InputLabel htmlFor="outlined-adornment-password">
              {t('password')}
            </InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword.password ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChangePassword}
              placeholder={t('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.password ? (
                      <VisibilityOff color="primary" />
                    ) : (
                      <Visibility color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              // label="Password"
            />
            <FormHelperText id="component-error-text">
              {errors.password && t(`common:::${errors.password}`)}
            </FormHelperText>
          </FormControl>
          <Box className="forgot-password" onClick={handleForgotPassword}>
            <Typography>{t('forgotPassword')}?</Typography>
          </Box>
          <Box marginY={3}>
            <CustomSliderCaptcha
              onSuccess={handleValidCaptcha}
              onChangeSession={handleChangeSession}
            />
            {Boolean(errors.captchaToken) && (
              <FormHelperText className="error_message">
                {errors.captchaToken && t(`common:::${errors.captchaToken}`)}
              </FormHelperText>
            )}
          </Box>

          {loadingLogin ? (
            <LoadingButton
              loading
              loadingPosition="start"
              variant="outlined"
              className="register-button"
            >
              {t('login')}
            </LoadingButton>
          ) : (
            <Button type="submit" className="login-button" variant="contained">
              {t('login')}
            </Button>
          )}

          <Box display="flex" justifyContent="center" className="redirect">
            <Typography fontSize="14px">
              {t('haveNotAccountQuestion')}?
            </Typography>
            <Link
              href={`${ROUTES.REGISTER_SSO}${location.search}`}
              className="redirect-link"
            >
              <Typography fontSize="14px" ml={1}>
                {t('register')}!
              </Typography>
            </Link>
          </Box>

          {/* {loadingLoginWallet ? (
            <LoadingButton
              loading
              loadingPosition="start"
              variant="outlined"
              className="login-with-wallet"
            >
              {t('loginWithWallet')}
            </LoadingButton>
          ) : (
            <Button
              className="login-with-wallet"
              variant="contained"
              onClick={handleLoginWithWallet}
            >
              {t('loginWithWallet')}
            </Button>
          )} */}

          {/* <Box textAlign="center" className="redirect">
            <Typography fontSize="14px">{t('orContinueWith')}</Typography>
            <StyledSocialLogin>
              <FacebookLogin
                appId={FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={handleCallbackFbLogin}
                icon={<img src="/img/facebook.svg" alt="facebook-icon" />}
                cssClass="facebook-button-login"
                textButton=""
                scope="public_profile"
              />
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText={false}
                render={(renderProps) => (
                  <Button
                    className="google-button"
                    onClick={renderProps.onClick}
                  >
                    <img src="/img/google.svg" alt="google-icon" />
                  </Button>
                )}
                autoLoad={false}
                onSuccess={handleLoginGoogleSuccess}
                onFailure={handleLoginGoogleFail}
                cookiePolicy="single_host_origin"
              />
            </StyledSocialLogin>
          </Box> */}
        </form>
      </Box>
    </StyledLogin>
  );
}
